import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { borderRadius, colors, gradients, margin, padding } from '@stewart/theme/index';

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      zIndex: '1110',
      backgroundColor: 'transparent !important',
      boxShadow: 'none !important',
    },
    toolbar: {
      paddingLeft: padding.xlarge22,
      alignItems: 'stretch!important',
      background: gradients.blueBackgroundDarkGradient,
      borderBottomLeftRadius: borderRadius.medium,
      borderBottomRightRadius: borderRadius.medium,
      height: '75px !important',
      justifyContent: 'space-between',
      '&.media': {
        [`@media only screen and (max-width: 768px)`]: {
          margin: '0 0px',
        },
        [`@media only screen and (min-width: 768px)`]: {
          margin: '0 32px',
        },
      },
    },
    logo: {
      height: '75px !important',
      marginRight: `${margin.xlarge} !important`,
      padding: `${padding.xsmall2} ${padding.zero} !important`,
      '@media (min-width: 1024px)': {
        width: '157px',
      },
    },
    profile: {
      alignItems: 'center !important',
      borderLeft: '1px solid #fff !important',
      borderRadius: `${borderRadius.zero} !important`,
      color: theme.palette.common.white,
      flexDirection: 'row',
      height: '39px !important',
      marginTop: '-4px !important',
      minWidth: 'auto !important',
      wordBreak: 'break-all',
      '&.media': {
        [`@media only screen and (max-width: 768px)`]: {
          padding: `${padding.xsmall2} ${padding.zero} ${padding.xsmall2} ${padding.small2} !important`,
        },
        [`@media only screen and (min-width: 768px)`]: {
          padding: `${padding.xsmall2} ${padding.zero} ${padding.xsmall2} ${padding.xlarge1} !important`,
        },
      },
    },
    profileIconContainer: {
      display: 'flex',
      marginRight: '5px',
    },
    profileName: {
      margin: '0 3px 0 6px',
      '&.media': {
        [`@media only screen and (max-width: 768px)`]: {
          display: 'none',
        },
        [`@media only screen and (min-width: 768px)`]: {
          display: 'block',
        },
      },
    },
    profileContainer: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      '&:before': {
        backgroundColor: colors.grey25,
        bottom: '0',
        content: '',
        height: '60%',
        left: '1px',
        position: 'absolute',
        top: '0',
        width: '10px',
      },
    },
    menuContainer: {
      background: 'white',
      borderRadius: borderRadius.small,
      minWidth: '110px',
      maxWidth: '225px',
      overflow: 'inherit',
      zIndex: '2000',
      '&:after, &:before': {
        border: 'solid transparent',
        bottom: '100%',
        content: `""`,
        height: '0',
        left: '50%',
        pointerEvents: 'none',
        position: 'absolute',
        width: '0',
      },
      '&:after': {
        borderBottomColor: 'white',
        borderColor: 'transparent',
        borderWidth: '10px',
        marginLeft: '-10px',
      },
    },
    navigation: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: '-20px',
      navigationItems: {
        padding: `${padding.xsmall2} ${padding.small2}`,
      },
    },
  })
);
