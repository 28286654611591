import { Components } from '@mui/material/styles/components';

import { createTheme } from '@mui/material';

// import { zIndex, defaultStyle, boxShadows, borderRadius, gradients, colors } from './defaultStyle';

declare module '@mui/material/styles' {
  /* eslint-disable no-unused-vars */
  interface Theme {
    custom: {
      common: any;
      container: any;
      textField: any;
    };
  }

  interface ThemeOptions {
    custom?: {
      common?: any;
      container?: any;
      textField?: any;
    };
  }

  /* eslint-enable no-unused-vars */
}
// const { label, control } = defaultStyle;
export const fontFamily = {
  primary: 'Inter',
};

export const fontSize = {
  base: '14px',
  xxsmall: '8px',
  xsmall: '10px',
  small: '12px',
  small1: '13px',
  medium: '14px',
  large: '16px',
  xlarge: '18px',
  xlarge0: '20px',
  xlarge1: '22px',
  xlarge2: '24px',
  xlarge3: '28px',
  xlarge4: '30px',
  xlarge5: '34px',
  xlarge6: '50px',
};

export const fontWeight = {
  light1: 100,
  light2: 200,
  light3: 300,
  normal1: 400,
  normal2: 500,
  bold1: 600,
  bold2: 700,
};

export const letterSpacing = {
  medium1: '0.15px',
  medium2: '0.17px',
};

export const lineHeight = {
  zero: '0px',
  large: '24px',
  xlarge: '32px',
  xxlarge: '58px',
};

export const margin = {
  zero: 0,
  xxsmall1: '1px',
  xxsmall2: '2px',
  xsmall: '4px',
  xsmall1: '5px',
  small: '6px',
  small_5: '7px',
  small1: '8px',
  small1_5: '9px',
  small2: '10px',
  medium: '12px',
  medium2: '15px',
  large: '16px',
  large1: '18px',
  xlarge: '20px',
  xlarge1: '24px',
  xlarge2: '30px',
  xlarge3: '32px',
  xlarge35: '35px',
  xlarge40: '40px',
  xlarge48: '48px',
  xlarge50: '50px',
};

export const padding = {
  zero: '0px',
  xsmall: '2px',
  xsmall1: '4px',
  xsmall2: '5px',
  small: '6px',
  small0: '7px',
  small1: '8px',
  small1_5: '9px',
  small2: '10px',
  medium1: '12px',
  medium2: '14px',
  large1: '16px',
  large2: '18px',
  xlarge: '20px',
  xlarge1: '24px',
  xlarge2: '30px',
  xlarge21: '32px',
  xlarge22: '48px',
  xlarge3: '50px',
  xxlarge1: '64px',
};

export const borderRadius = {
  zero: '0px',
  xsmall: '2px',
  xsmall1: '3px',
  small: '4px',
  medium: '6px',
  large: '8px',
  xlarge: '12px',
  xlarge1: '16px',
};

export const width = {
  xsmall: '200px',
  xsmall1: '300px',
  small: '400px',
  medium: '600px',
  large: '800px',
  xlarge: '1200px',
  xlarge1: '1600px',
};
export const height = {
  xsmall: '250px',
  xsmall1: '300px',
  small: '400px',
  medium: '600px',
  large: '800px',
  xlarge: '1200px',
  xlarge1: '1600px',
};

export const borderSize = {
  zero: '0px',
  xsmall: '1px',
  small: '2px',
  medium: '3px',
  large: '4px',
  xlarge: '5px',
  xxxlarge: '16px',
};

export const iconSize = {
  xxsmall: '9px',
  small1: '16px',
  small2: '18px',
  small3: '20px',
  medium: '24px',
  medium2: '28px',
  large1: '32px',
  large2: '38px',
  large3: '40px',
  xlarge: '72px',
};

export const colors = {
  // --- Blue ---
  blue01: '#0075AA',
  blue02: '#03567B',
  blue03: '#004D82',
  blue04: '#558FAA',
  blue05: '#015E88',
  blue06: '#01415F',
  blue07: '#B2D5E5',
  blue08: '#E6F1F7',
  blue09: '#659AB1',
  blue10: '#1976D2',
  blue11: '#92C4DB',
  blue12: '#71A7D5',
  blue13: '#E9F8FF',
  blue14: '#2E4289',
  blue15: '#E1E6F6',
  blue16: '#223266',
  blue17: '#A6B3E1',
  blue18: '#4A66C3',
  blueTransparent01: 'rgba(25, 118, 210, 0.04)',
  blueDisabled: '#8799D7',
  // --- Grey ---
  grey01: '#e5e5e5',
  grey02: '#f5f4f4',
  grey03: '#f2f2f2',
  grey04: '#e8e5e5',
  grey05: '#c7cdd5',
  grey06: '#ADADAD',
  grey07: '#626262',
  grey08: '#212121',
  grey09: '#BDBDBD',
  grey10: '#F5F5F5',
  grey11: '#7F7F7F',
  grey12: '#EBEBEB',
  grey13: '#797979',
  grey14: '#c4c4c4',
  grey15: '#949494',
  grey16: '#8b8b8b',
  grey17: '#e0e0e0',
  grey18: '#A6A6A6',
  grey19: '#D2D9DB',
  grey20: '#00000099',
  grey21: '#F9F9F9',
  grey22: '#666666',
  grey23: '#EDF2F2',
  grey24: '#3C494C',
  grey25: '#333333',
  grey26: '#DEDEDE',
  // --- Marron ---
  maroon01: '#9e2339',
  maroon02: '#9E233A',
  maroon03: '#620000',
  maroon04: '#3A0000',
  // --- Red ---
  red01: '#E00000',
  red02: '#DC3545',
  // --- Green ---
  green: '#00875A',
  // --- Black ---
  black: '#000000',
  black01: '#212121',
  // --- White ---
  white: '#ffffff',
  whiteTransparent01: 'rgba(255, 255, 255, 0.75)',
  // --- Yellow ---
  yellow: '#FFC400',
  activeBlueDisabled: '#558FAA',
  darkBlue: '#E1E6F6',
  grey: '#808080',
  grey100: '#e5e5e5',
  grey150: '#f5f4f4',
  grey200: '#f2f2f2',
  grey300: '#e8e5e5',
  grey400: '#e5e5e5',
  grey500: '#c7cdd5',
  grey600: '#adadad',
  grey900: '#626262',
  grey1000: '#797979',
  lightMaroon: '#9e2339',
  errorColor: '#E00000',
  successColor: '#2e7d32',
  backgroundSuccess: '#e8f2e7',
  orange: '#ff9933',
  mainText: '#212121',
  lightBlue: '#E6F1F7',
  aliceBlue: '#2E4289',
  deactivateGrey: '#D3D3D3',
};

export const gaps = {
  zero: '0',
  xsmall1: '3px',
  xsmall2: '4px',
  xsmall2_5: '6px',
  xsmall3: '7px',
  small1: '8px',
  small2: '10px',
  small3: '12px',
  medium1: '16px',
  medium2: '20px',
  large1: '24px',
  large2: '30px',
  large3: '32px',
  large4: '35px',
};

export const gradients = {
  // --- Blue ---
  blueGradient01: 'linear-gradient(#189AD4, #2E4289)', // Action Blue Gradient
  blueGradient02: 'linear-gradient(#6AB3D4, #558FAA)', // Action Blue Gradient Disabled
  blueGradient03: 'linear-gradient(#2E4289, #004D82)', // Action Blue Gradient Dark
  blueGradient04: 'linear-gradient(#558FAA, #416882)', // Action Blue Gradient Dark Disabled
  blueGradient05: 'linear-gradient(#004D82, #01415F)', // Action Blue Gradient Darker
  blueGradient06: 'linear-gradient(#416882, #2F505F)', // Action Blue Gradient Darker Disabled
  blueGradient07: 'linear-gradient(#189AD5, #2E4289)',
  blueGradient08: 'linear-gradient(180deg, #5994C6 0%, #006091 100%)',
  blueGradient09: 'var(--Gradient, linear-gradient(180deg, #2E4289 0%, #004D82 100%))',
  blueGradient10:
    'var(--Light-Primary-Shades-30p-Ripple, radial-gradient(36.59% 100.8% at 50% 50%, rgba(25, 118, 210, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%))',
  blueGradient11:
    'var(--Common-White-30p-Ripple, radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%))',
  // --- Grey ---
  greyGradient01: 'linear-gradient(#7F7F7F, #555555)',
  // --- Maroon ---
  maroonGradient01: `linear-gradient(${colors.maroon01}, ${colors.maroon03})`,
  maroonGradient02: `linear-gradient(${colors.maroon03}, ${colors.maroon03})`,
  maroonGradient03: `linear-gradient(${colors.maroon04}, ${colors.black})`,
  // --- Red ---
  redGradient01: 'linear-gradient(#E00000, #860000)',
  redGradient02: 'linear-gradient(180deg, #B3261E 0%, #8C1D18 100%)',
  redGradient03: '-webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%)',
  // --- Green ---
  greenGradient01: 'linear-gradient(#00875A, #006E49)',
  // --- Yellow ---
  yellowGradient01: 'linear-gradient(#FFC400, #FFAB00)',
  yellowGradient02: 'linear-gradient(180deg, #FFC400 0%, #FF9400 100%)',
  actionBlueGradient: 'linear-gradient(#189AD4, #2E4289)',
  actionBlueGradientDisabled: 'linear-gradient(#6AB3D4, #558FAA)',
  actionBlueGradientDark: 'linear-gradient(#2E4289, #004D82)',
  actionBlueGradientDarkDisabled: 'linear-gradient(#004D82, #416882)',
  errorGradient: 'linear-gradient(#E00000, #860000)',
  darkGreyGradient: 'linear-gradient(#7F7F7F, #555555)',
  successGreenGradient: 'linear-gradient(#00875A, #006E49)',
  yellowWarningGradient: 'linear-gradient(#FFC400, #FFAB00)',
  darkBlueGradient: 'linear-gradient(#2E4289, #223266)',
  blueBackgroundMediumGradient:
    'var(--Admin-Blueberry-Gradient-Medium, linear-gradient(180deg, #3953AB 21%, #2E4289 92%))',

  blueBackgroundDarkGradient:
    'var(--Admin-Blueberry-Gradient-Dark, linear-gradient(180deg, #2E4289 0%, #223266 100%))',

  // actionBlueGradient: 'linear-gradient(#189AD4, #0075AA)',
  // actionBlueGradientDark: 'linear-gradient(#0075AA, #004D82)',
};

export const boxShadows = {
  boxShadow01:
    '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
  boxShadow02: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  boxShadow03:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  boxShadow04: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  boxShadow05: '0px 4px 6px rgb(0 0 0 / 25%)',
  boxShadow06: '0px 2px 6px rgba(0, 0, 0, 0.25)',
  boxShadow07:
    '0px 3px 3px -2px rgba(106, 116, 120, 0.20), 0px 3px 4px 0px rgba(106, 116, 120, 0.14), 0px 1px 8px 0px rgba(106, 116, 120, 0.12)',
  boxShadow08:
    '0px 3px 10px -2px rgba(106, 116, 120, 0.10), 0px 2px 2px 0px rgba(106, 116, 120, 0.04), 0px 1px 5px 0px rgba(106, 116, 120, 0.02)',
  boxShadow09: '0px 1px 8px 0px #6A74781F, 0px 3px 4px 0px #6A747824, 0px 3px 3px -2px #6A747833',
  boxShadowForSelect:
    '0px 1px 14px 0px #6A74781F, 0px 5px 8px 0px #6A747824, 0px 3px 5px -1px #6A747833',
  boxShadow11: '0px 1px 5px 0px #6A747805, 0px 2px 2px 0px #6A74780A, 0px 3px 10px -2px #6A74781A',
};

export const filters = {
  filter01: 'drop-shadow(0px 2px 6px #C2D8E3)',
};

export const zIndex = {
  negativeMax: -2147483648,
  negativeMin: -1,
  zero: 0,
  positiveMin: 1,
  low1: 100,
  high0: 1000,
  high1: 2000,
  high2: 3000,
  high3: 4000,
  positiveMax: 2147483647,
};

export const mediaSize = {
  minXs: 0,
  maxXs: 359,
  minSm: 360,
  maxSm: 767,
  minMd: 768,
  maxMd: 1023,
  minMd1: 1024,
  maxMd2: 1279,
  minLg: 1280,
  maxLg: 1439,
  minXlg: 1440,
  maxXlg: 1919,
  minXlg1: 1920,
};

export const titleIconOverflowDistance = '13px';
export const scrollBar = {
  borderRadius: '4px',
  borderRadius1: '9px',
  height: '4px',
  width: '4px',
  width1: '9px',
};

export const defaultStyle = {
  section: {
    borderRadius: borderRadius.xlarge,
    backgroundColor: colors.white,
    boxShadow: boxShadows.boxShadow09,
    outer: {
      padding: padding.xlarge1,
    },
    inner: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  label: {
    outer: {
      fontSize: fontSize.medium,
      paddingLeft: padding.large1,
      color: colors.grey07,
    },
    inner: {
      fontSize: fontSize.xlarge,
      paddingLeft: padding.medium1,
      color: colors.grey06,
      paddingRight: padding.medium1,
    },
  },
  control: {
    fontSize: fontSize.xlarge,
    borderRadius: borderRadius.small,
    height: '40px', // input height 26 = 40 (control height) - 6(padding top) + - (padding bottom) - 1 (border top) - 1 (border bottom)
    borderSize: borderSize.xsmall,
    paddingTop: padding.small,
    paddingBottom: padding.small,
    paddingLeft: padding.medium1,
    paddingRight: padding.medium1,
    backgroundColor: colors.white,
    borderColor: colors.grey09,
    borderHoverColor: colors.blue14,
    borderFocusColor: colors.blue14,
    errorColor: colors.red02,
    disabled: {
      backgroundColor: colors.grey10,
      color: colors.grey11,
      borderColor: colors.grey11,
    },
  },
  // use for dropdownlist box
  paper: {
    // borderColor: colors.white,
    maxHeight: '300px',
    borderWidth: borderSize.medium,
    borderColor: 'primary.main',
  },
  button: {
    height: '35px',
    borderRadius: borderRadius.small,
    fontSize: fontSize.base,
    paddingLeft: padding.large1,
    paddingRight: padding.large1,
    outlinePrimary: {
      color: colors.white,
      background: gradients.blueGradient03,
      boxShadow: boxShadows.boxShadow09,
      backgroundColor: colors.blue14,
      borderColor: colors.grey05,
      focus: {
        color: colors.white,
        backgroundColor: colors.blue03,
        borderColor: colors.grey05,
      },
      hover: {
        background: gradients.blueGradient05,
        borderColor: colors.grey05,
        boxShadow: 'none',
        color: colors.white,
      },
    },
    outlineSecondary: {
      backgroundColor: colors.white,
      borderColor: colors.blue14,
      boxShadow: boxShadows.boxShadow09,
      color: colors.blue14,
      focus: {
        color: colors.blue03,
        backgroundColor: colors.white,
        borderColor: colors.blue03,
      },
      hover: {
        color: colors.blue03,
        backgroundColor: colors.white,
        borderColor: colors.blue03,
      },
      disabled: {
        backgroundColor: colors.grey10,
        borderColor: colors.blue09,
        color: colors.blue09,
      },
    },
  },
  checkbox: {
    borderRadius: borderRadius.xsmall,
    borderColor: colors.grey11,
  },
  radioButton: {
    borderColor: colors.grey11,
  },
  select: {
    border: `${borderSize.xsmall} solid ${colors.grey14}`,
  },
  table: {
    border: `${borderSize.xsmall} solid ${colors.grey09}`,
  },
  icon: {
    formSection: {
      width: iconSize.medium,
      height: iconSize.medium,
      gradient1: colors.maroon01,
      gradient2: colors.maroon03,
    },
  },
};

const custom = {
  textField: {
    labelPlaceholder: {
      zIndex: zIndex.positiveMin,
      paddingLeft: `${defaultStyle.label.inner.paddingLeft}`,
      color: defaultStyle.label.inner.color,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '90%',
      height: '100%',
      pointerEvents: 'none',
      paddingRight: `${defaultStyle.label.inner.paddingRight}`,
    },
    label: {
      zIndex: zIndex.positiveMin,
      paddingLeft: `${defaultStyle.label.inner.paddingLeft}`,
      color: defaultStyle.label.inner.color,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      paddingRight: `${defaultStyle.label.inner.paddingRight}`,
    },
    labelFocus: {
      color: defaultStyle.label.outer.color,
      overflow: 'unset',
    },
    labelShrink: {
      transform: `translate(0, -5px) scale(0.78)`,
      color: defaultStyle.label.outer.color,
      paddingLeft: `${defaultStyle.label.outer.paddingLeft}`,
    },
    // Input
    input: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderRadius: `${defaultStyle.control.borderRadius}`,
      paddingLeft: `${defaultStyle.control.paddingLeft}`,
      paddingRight: `${defaultStyle.control.paddingRight} !important`,
      paddingTop: `${defaultStyle.control.paddingTop}`,
      paddingBottom: `${defaultStyle.control.paddingBottom}`,
      backgroundColor: defaultStyle.control.backgroundColor,
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderColor}`,
      height: `${defaultStyle.control.height}`,
    },
    inputDate: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingLeft: '6px',
      paddingRight: `${defaultStyle.control.paddingRight}`,
      paddingTop: `${defaultStyle.control.paddingTop}`,
      paddingBottom: `${defaultStyle.control.paddingBottom}`,
      backgroundColor: defaultStyle.control.backgroundColor,
      marginLeft: '6px',
    },
    inputHover: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderHoverColor}`,
    },
    inputFocus: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderFocusColor}`,
    },
    inputDisabled: {
      color: defaultStyle.control.disabled.color,
      backgroundColor: defaultStyle.control.disabled.backgroundColor,
      padding: 0,
      paddingLeft: '6px',
      paddingRight: `${defaultStyle.control.paddingRight}`,
      height: '36px',
      '&:hover': {
        border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderColor}`,
      },
    },
    inputOutlined: {
      backgroundColor: defaultStyle.control.backgroundColor,
      borderRadius: defaultStyle.control.borderRadius,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    inputOutlinedDisabled: {
      color: defaultStyle.control.disabled.color,
      backgroundColor: defaultStyle.control.disabled.backgroundColor,
      borderRadius: defaultStyle.control.borderRadius,
    },
    // AutoComplete
    autoComplete: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      borderRadius: `${defaultStyle.control.borderRadius}`,
      paddingLeft: `${defaultStyle.control.paddingLeft}`,
      paddingRight: `${defaultStyle.control.paddingRight}`,
      paddingTop: `0px`,
      paddingBottom: `0px`,
      backgroundColor: defaultStyle.control.backgroundColor,
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderColor}`,
    },
    autoCompleteHover: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderHoverColor}`,
    },
    autoCompleteFocus: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderHoverColor}`,
    },

    // DropDownList (Select)
    select: {
      overflow: 'hidden',
      borderRadius: `${defaultStyle.control.borderRadius}`,
      paddingLeft: `${defaultStyle.control.paddingLeft}`,
      paddingRight: `${defaultStyle.control.paddingRight}`,
      paddingTop: `${defaultStyle.control.paddingTop}`,
      paddingBottom: `${defaultStyle.control.paddingBottom}`,
      backgroundColor: defaultStyle.control.backgroundColor,
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderColor}`,
      height: '26px',
      fontSize: `${defaultStyle.control.fontSize}`,
      lineHeight: '26px',
      // transition: defaultTheme.transitions.create(["border-color", "box-shadow"])
    },
    selectHover: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.borderHoverColor}`,
    },
    selectFocus: {
      borderColor: `${defaultStyle.control.borderFocusColor}`,
    },
    selectDisabled: {
      color: defaultStyle.control.disabled.color,
      backgroundColor: defaultStyle.control.disabled.backgroundColor,
      border: 'none',
    },
    selectError: {
      border: `${defaultStyle.control.borderSize} solid ${defaultStyle.control.errorColor}`,
    },
    colorError: {
      color: defaultStyle.control.errorColor,
    },
    arrowError: {
      fill: defaultStyle.control.errorColor,
    },
  },
};
const palette = {
  primary: {
    main: colors.blue14,
    dark: colors.darkBlue,
  },
  secondary: {
    main: colors.grey500,
    light: colors.grey200,
    dark: colors.grey600,
    contrastText: colors.grey900,
  },
  background: {
    default: colors.grey400,
  },
  error: {
    main: colors.errorColor,
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1280,
    xl: 1920,
  },
};

const typography = {
  h3: {
    fontWeight: 700,
    fontSize: '20px',
  },
  h4: {
    fontWeight: 700,
    fontSize: fontSize.medium,
  },
  h6: {
    fontWeight: 700,
    fontSize: '18pt',
    marginBottom: '0px',
  },
  fontSize: 12,
  fontFamily: fontFamily.primary,
};

const components: Components = {
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: colors.blue14,
          color: colors.white,
          '&:hover': {
            backgroundColor: colors.blue14,
            color: colors.white,
          },
        },
        '&:hover': {
          backgroundColor: colors.blue14,
          color: colors.white,
        },
        '&.columns:hover': {
          backgroundColor: 'inherit',
          color: colors.white,
        },
        '&.select-field:not(.Mui-selected)': {
          color: colors.grey,
          '&:hover': {
            color: colors.white,
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: fontSize.medium,
        fontWeight: 400,
        border: '1px solid transparent',
        borderRadius: '4px',
        lineHeight: '19.36px',

        '& fieldset': {
          borderColor: colors.grey200,
          borderWidth: '1px!important',
        },

        '&:hover fieldset': {
          borderColor: `${colors.blue14}!important`,
        },

        '& input, .MuiSelect-select': {
          padding: '8px 12px',
          borderRadius: '4px',
        },

        '& input:disabled': {
          backgroundColor: colors.grey200,
          color: colors.grey900,
          overflow: 'hidden',
        },

        '&.select fieldset legend span': {
          display: 'none',
        },

        '&.select .Mui-disabled': {
          backgroundColor: colors.grey200,
        },

        '&.Mui-disabled fieldset': {
          borderColor: 'transparent!important',
        },
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        width: '100%',

        '& label': {
          marginTop: '-4px',
          color: colors.grey600,
          '&.MuiInputLabel-shrink': {
            padding: '0px 8px',
            top: '5px',
            color: colors.black,
            fontSize: '12px',
            fontWeight: 400,
          },

          '& .MuiTypography-root': {
            color: colors.black,
            fontSize: fontSize.medium,
          },
        },
        '& fieldset legend': {
          width: 0,
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.blue14,
        transform: 'scale(1.15)',
        '&.Mui-checked': {
          color: colors.blue14,
        },
        '&.Mui-disabled': {
          color: colors.blueDisabled,
          backgroundColor: 'transparent',
          borderRadius: '2px',
        },
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'contained' },
        style: {
          borderRadius: borderRadius.small,
          background: colors.blue14,
          color: colors.white,
          boxShadow: boxShadows.boxShadow07,
          '&:hover': {
            background: colors.blue16,
          },
          '& .MuiTouchRipple-child': {
            background: gradients.blueGradient11,
          },
          '&.Mui-disabled': {
            background: colors.blueDisabled,
            color: colors.white,
          },
        },
      },
      {
        props: { variant: 'outlined' },
        style: {
          backgroundColor: colors.white,
          color: colors.blue14,
          border: `1px solid ${colors.blue14}`,
          '&:hover': {
            backgroundColor: colors.blueTransparent01,
          },
          '& .MuiTouchRipple-child': {
            background: gradients.blueGradient10,
          },
          '&.Mui-disabled': {
            background: colors.grey10,
            color: colors.blueDisabled,
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        textTransform: 'none',
        padding: '6px 16px',
        fontWeight: 600,
        fontSize: fontSize.medium,
        lineHeight: '21px',
        '& .MuiButton-startIcon': {
          marginLeft: '0 !important',
        },
        '& .MuiButton-endIcon': {
          marginRight: '0 !important',
        },
        '&.Mui-disabled': {
          color: colors.blueDisabled,
        },
        '&:hover': {
          backgroundColor: colors.blueTransparent01,
        },
        '& .MuiTouchRipple-child': {
          background: colors.blue15,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiTableContainer-root': {
          maxHeight: 'calc(100vh - 455px)',
          minHeight: '395px',
          boxShadows: boxShadows.boxShadow11,
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '14pt',
        fontWeight: 700,
        color: colors.white,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        width: '99%',
        boxShadow: boxShadows.boxShadow11,
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
        '&.Mui-selected': {
          backgroundColor: 'white',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '12px 16px',

        '&:last-child': {
          borderRight: `none`,
        },
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        '&:not(.Mui-active):hover': {
          '.grid-sort-icon': {
            visibility: 'visible',
          },
        },

        '&.Mui-active': {
          '.MuiTableSortLabel-icon.grid-sort-icon': {
            opacity: '1 !important',
          },
        },

        '.MuiTableSortLabel-icon.grid-sort-icon': {
          opacity: '0.5 !important',
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {},
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {},
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '.MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input': {
          padding: '2px 4px 2px 6px',
        },

        '.MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 12px)',
        },

        '.MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
          marginTop: '0px',
        },

        '.Mui-disabled': {
          backgroundColor: colors.grey200,
        },
      },
    },
  },
};

const theme = createTheme({
  palette,
  breakpoints,
  typography,
  components,
  custom,
});

export default theme;
