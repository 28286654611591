import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { fontSize, fontWeight, gradients, margin, padding } from '@stewart/theme/index';

export const useNavigationItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      minWidth: 'auto !important',
      padding: '13.5px 12px',
      position: 'relative',
      width: 'auto',
      '&.media': {
        '@media (max-width:1440px)': {
          flexDirection: 'row',
          justifyContent: 'left',
          margin: `${margin.xsmall} ${margin.zero}`,
          padding: `${padding.small1} ${padding.small2}`,
          width: '100%',
        },
      },
      '&:before': {
        backgroundColor: 'transparent',
        bottom: 0,
        content: '""',
        height: '2px',
        left: 0,
        position: 'absolute',
        width: '100%',
        transition: 'width .2s ease-in-out',
        '@media (max-width:1440px)': {
          height: '0',
          width: '0',
        },
      },
      '&:hover:before': {
        backgroundColor: theme.palette.common.white,
        '@media (max-width:1440px)': {
          height: '0',
          width: '0',
        },
      },
    },
    active: {
      '@media (max-width:1440px)': {
        background: gradients.blueBackgroundMediumGradient,
      },
      '&:before': {
        backgroundColor: theme.palette.common.white,
        '@media (max-width:1440px)': {
          height: '0',
          width: '0',
        },
      },
    },
    icon: {
      color: theme.palette.common.white,
    },
    title: {
      color: theme.palette.common.white,
      fontSize: fontSize.medium,
      fontWeight: `${fontWeight.bold1} !important`,
      letterSpacing: '0.17px',
      lineHeight: '20.02px',
      position: 'relative',
      textTransform: 'none',
      '&.media': {
        '@media (max-width:1440px)': {
          marginLeft: margin.small2,
        },
      },
    },
  })
);
