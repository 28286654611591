import React, { ChangeEventHandler, FocusEventHandler, KeyboardEvent } from 'react';

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { Field, getIn } from 'formik';
import { FieldAttributes } from 'formik/dist/Field';
import { useSelector } from 'react-redux';

import MaybeComponent from '@stewart/common-ui/components/MaybeComponent';
import { useStewartInputStyles } from '@stewart/common-ui/components/StewartInput/style';
import { PermissionStore } from '@stewart/core/redux/reducers/permission.reducer';
import { RootStateType } from '@stewart/core/redux/redux.models';
import { colors } from '@stewart/theme/index';

type StewartInputProps<T> = {
  type?: string;
  name: keyof T;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  rightElement?: ReactJSXElement;
  leftElement?: ReactJSXElement;
  controlStyle?: any;
  inputClassName?: any;
  readOnly?: boolean;
  disabled?: boolean;
  inputComponent?: React.ElementType<InputBaseComponentProps>;
  inputProps?: InputBaseComponentProps;
  isWarning?: boolean;
  permissionCode?: string;
  required?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  clearInput?: boolean;
  clearFunction?: (value: string) => any;
  page?: string;
  setFilter?: any;
  filters?: any;
  isGrid?: boolean;
};

const StewartInput = <T,>({
  type,
  name,
  placeholder,
  onChange = undefined,
  onBlur,
  rightElement,
  leftElement,
  controlStyle,
  inputClassName,
  readOnly,
  disabled,
  inputComponent,
  isWarning,
  permissionCode,
  inputProps = {},
  required = false,
  onKeyDown,
  clearInput,
  setFilter,
  filters,
  isGrid,
}: StewartInputProps<T>) => {
  const classes = useStewartInputStyles();
  return (
    <Field name={name}>
      {({ field, form }: FieldAttributes<any>) => {
        const { errors, touched } = form;
        const errorMessage = getIn(errors, name.toString());
        const isTouched = getIn(touched, name.toString());
        const isInvalid = Boolean(isTouched && errorMessage);
        const { permissionCodes } = useSelector<RootStateType, PermissionStore>(
          (state) => state.permission
        );
        const placeholderWithRequired = required ? `${placeholder}*` : placeholder;
        return (
          <FormControl
            error={isInvalid}
            style={{
              marginTop: '15px',
              marginBottom: '30px',
              ...controlStyle,
            }}
          >
            {type === 'date' ? (
              <InputLabel
                className={`${isInvalid && classes.errorLabel} ${classes.label}`}
                htmlFor={name.toString()}
                shrink
              >
                {placeholderWithRequired}
              </InputLabel>
            ) : (
              <InputLabel
                className={`${isInvalid && classes.errorLabel} ${classes.label}`}
                style={{ textTransform: 'capitalize' }}
                htmlFor={name.toString()}
              >
                {placeholderWithRequired}
              </InputLabel>
            )}
            <OutlinedInput
              {...field}
              type={type}
              id={name}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              autoComplete="off"
              readOnly={readOnly}
              disabled={(permissionCode && !permissionCodes.includes(permissionCode)) || disabled}
              startAdornment={
                leftElement && <InputAdornment position="start">{leftElement}</InputAdornment>
              }
              endAdornment={
                rightElement &&
                (!clearInput ? (
                  <InputAdornment variant="filled" position="end">
                    {rightElement}
                  </InputAdornment>
                ) : (
                  field.value !== '' &&
                  isGrid && (
                    <IconButton
                      onClick={() => {
                        form.setFieldValue(String(name), '');
                        setFilter(() => ({
                          ...filters,
                          [name]: '',
                        }));
                      }}
                      sx={{ color: colors.blue14 }}
                    >
                      {rightElement}
                    </IconButton>
                  )
                ))
              }
              inputComponent={inputComponent}
              inputProps={inputProps}
              className={isWarning ? `${classes.warning} warning-input` : inputClassName}
              required={required}
              onKeyDown={onKeyDown}
            />

            <MaybeComponent isVisible={isInvalid}>
              <FormHelperText className={classes.error}>{errorMessage}</FormHelperText>
            </MaybeComponent>
          </FormControl>
        );
      }}
    </Field>
  );
};

StewartInput.defaultProps = {
  placeholder: '',
  rightElement: null,
  leftElement: null,
  type: 'text',
  controlStyle: {},
  inputClassName: '',
  readOnly: false,
  onBlur: () => null,
  disabled: false,
  inputComponent: null,
  isWarning: false,
  permissionCode: '',
  inputProps: {},
  onChange: undefined,
  required: false,
  onKeyDown: undefined,
};

export default StewartInput;
