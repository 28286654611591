import { HandleSubmitType } from '@stewart/common-ui/dialogs/StewartForm/models';
import {
  ApprovalInfoFieldsType,
  APPROVAL_INITIAL_VALUES,
} from '@stewart/pages/UWPage/NewApprovalInfo/models';

import { AgencyInfoFieldsType, AGENCY_INFO_INITIAL_VALUES } from './NewAgencyInfo/models';
import { CancellationFieldsType, CANCELLATION_INITIAL_VALUES } from './NewCancellationInfo/models';
import { DeclinationFieldsType, DECLINATION_INITIAL_VALUES } from './NewDeclinationInfo/models';
import { MoreInfoFormType, MORE_INFO_INITIAL_VALUES } from './NewMoreInfo/models';
import {
  ReviseRequestDto,
  RevisionFieldsType,
  REVISION_INITIAL_VALUES,
} from './NewRevisionInfo/models';
import { SpecialRisksFormType, SPECIAL_RISKS_INITIAL_VALUES } from './SpecialRisks/models';

export type GeneralUWRequestFieldsType = {
  requestID: number;
};

export type UWRequestWholeBody = GeneralUWRequestFieldsType &
  ApprovalInfoFieldsType &
  CancellationFieldsType &
  DeclinationFieldsType &
  RevisionFieldsType;

export type HandleSubmitRefStore = {
  newApprovalInfo?: HandleSubmitType;
  newContacts?: HandleSubmitType;
  newAgencyInfo?: HandleSubmitType;
  specialRisks?: HandleSubmitType;
  moreInfo?: HandleSubmitType;
  newProperties?: HandleSubmitType;
};

export type ApprovalCalculationBodyDto = {
  isLandAbutOpen: boolean;
  isTitleToAccessEasementExamined: boolean;
  isLienBasedOnSubordinationAgreement: boolean;
  isNoticeOfCompletionFiled: boolean;
  isConstructionLoan: boolean;
  isBrokenPriority: boolean;
  isRecentConstruction: boolean;
  hasMechanicsLienCoverageRequest: boolean;
  is30MillionOrMore: boolean;
  isLienPriorityBasedOnSubordinationAgreement: boolean;
  isTidelands: boolean;
  isRailroadProperty: boolean;
  isJudicialProceeding: boolean;
  isForeclosure: boolean;
  hasMineralCoverage: boolean;
  isLoanConstructionForeclosure: boolean;
  isEasementIndependantOfProperty: boolean;
  hasInsuredOption: boolean;
  isNativeAmericanLand: boolean;
  hasWaterRights: boolean;
  hasSheriffSale: boolean;
  isInsuringRecordedLien: boolean;
  isAssignmentOfInterest: boolean;
  hasPurchaseContract: boolean;
  hasRelianceOnIndemnity: boolean;
  hasSurveyConcerns: boolean;
  isOwnerOutOfPossession: boolean;
  hasOtherExtraHazardRisk: boolean;
  hasLitigation: boolean;
  hasTransactionTurnedDown: boolean;
  isEnergyProject: boolean;
  isCannabisProject: boolean;
  isHardMoney: boolean;
  hasUnusualCoverages: boolean;
  hasCoInsurance: boolean;
};

export const APPROVAL_LEVEL_CALCULATION_DEFAULT_BODY: ApprovalCalculationBodyDto = {
  isLandAbutOpen: false,
  isTitleToAccessEasementExamined: false,
  isLienBasedOnSubordinationAgreement: false,
  isNoticeOfCompletionFiled: false,
  isConstructionLoan: false,
  isBrokenPriority: false,
  isRecentConstruction: false,
  hasMechanicsLienCoverageRequest: false,
  is30MillionOrMore: false,
  isLienPriorityBasedOnSubordinationAgreement: false,
  isTidelands: false,
  isRailroadProperty: false,
  isJudicialProceeding: false,
  isForeclosure: false,
  hasMineralCoverage: false,
  isLoanConstructionForeclosure: false,
  isEasementIndependantOfProperty: false,
  hasInsuredOption: false,
  isNativeAmericanLand: false,
  hasWaterRights: false,
  hasSheriffSale: false,
  isInsuringRecordedLien: false,
  isAssignmentOfInterest: false,
  hasPurchaseContract: false,
  hasRelianceOnIndemnity: false,
  hasSurveyConcerns: false,
  isOwnerOutOfPossession: false,
  hasOtherExtraHazardRisk: false,
  hasLitigation: false,
  hasTransactionTurnedDown: false,
  isEnergyProject: false,
  isCannabisProject: false,
  isHardMoney: false,
  hasUnusualCoverages: false,
  hasCoInsurance: false,
};

export type ApprovalCalculationDto = {
  countryCode: number;
  stateAbbr: string;
  liabilityAmount: string;
  moreInfo: ApprovalCalculationBodyDto;
};

export const APPROVAL_LEVEL_CALCULATION_DEFAULT_VALUES: ApprovalCalculationDto = {
  countryCode: 0,
  stateAbbr: '',
  liabilityAmount: '',
  moreInfo: APPROVAL_LEVEL_CALCULATION_DEFAULT_BODY,
};

export type CurrentRequestType = {
  requestStatus: string;
  requestId: number;
  approvalInitialValues: ApprovalInfoFieldsType;
  cancellationInitialValues: CancellationFieldsType;
  declinationInitialValues: DeclinationFieldsType;
  revisionInitialValues: ReviseRequestDto;
  moreInfoInitialValues: MoreInfoFormType;
  specialRisksInitialValues: SpecialRisksFormType;
  isRevised: boolean;
  currentApprovalState: string;
  isNewRequest: boolean;
  agencyInfoInitialValues: AgencyInfoFieldsType;
};

export type UWStore = {
  levelCalculationData: ApprovalCalculationDto;
  requiredFields: RequiredFieldsType;
};

export const CURRENT_REQUEST_INITIAL_VALUES: CurrentRequestType = {
  requestStatus: '',
  requestId: 0,
  approvalInitialValues: APPROVAL_INITIAL_VALUES,
  cancellationInitialValues: CANCELLATION_INITIAL_VALUES,
  declinationInitialValues: DECLINATION_INITIAL_VALUES,
  revisionInitialValues: REVISION_INITIAL_VALUES,
  moreInfoInitialValues: MORE_INFO_INITIAL_VALUES,
  specialRisksInitialValues: SPECIAL_RISKS_INITIAL_VALUES,
  isRevised: false,
  currentApprovalState: '',
  isNewRequest: true,
  agencyInfoInitialValues: AGENCY_INFO_INITIAL_VALUES,
};

export type CurrentUserDataType = {
  uwaUserTypeCode: string;
  uwaApprovalLevel: number;
};

export const CURRENT_USER_OWNER_DATA_INITIAL_VALUES = {
  uwaUserTypeCode: '',
  uwaApprovalLevel: 0,
};

export type RequiredFieldsType = {
  requestedAmount: string;
  approvalState: string;
  policyType: string;
  fileNumber: string;
  agency: string;
  requesterName: string;
  requesterEmail: string;
  propertyType: string;
};

export const REQUIRED_FIELDS_INITIAL_VALUES: RequiredFieldsType = {
  requestedAmount: '',
  approvalState: '',
  policyType: '',
  fileNumber: '',
  agency: '',
  requesterName: '',
  requesterEmail: '',
  propertyType: '',
};
