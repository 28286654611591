import React, { useEffect } from 'react';

import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Box } from '@mui/material';
import { colors } from '@stewart/theme/index';

import { AuthenticatedApp } from '@stewart/common-ui/components/AuthenticatedApp';
import StewartFooter from '@stewart/common-ui/components/StewartFooter';
import StewartHeader from '@stewart/common-ui/components/StewartHeader';
import { useAuth } from '@stewart/core/contexts/Auth';
import Router from '@stewart/core/routing/Router';
import { ROOT_ROUTES } from '@stewart/core/routing/routes/root.routes';
import { useHistory, useLocation } from 'react-router';

function App() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem('isReload', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen((path, action) => {
      sessionStorage.setItem('navigationMode', action);

      if (action === 'PUSH') {
        sessionStorage.setItem('isReload', 'false');
      }
    });
    return () => unlisten();
  }, [history]);

  const { loginRequest, passwordResetRequest, instance } = useAuth();

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (eventMessage: EventMessage) => {
      if (eventMessage.eventType === EventType.LOGIN_FAILURE) {
        if (eventMessage.error?.message.includes('AADB2C90118')) {
          await instance.loginRedirect(passwordResetRequest);
        }

        if (eventMessage.error?.message.includes('no_cached_authority_error')) {
          window.location.reload();
        }

        if (eventMessage.error?.message.includes('AADB2C90091')) {
          await instance.loginRedirect(loginRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, loginRequest, passwordResetRequest]);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <AuthenticatedApp>
          <Box>
            <Box
              style={{
                minHeight: 'calc(100vh - 135px)',
                backgroundColor: colors.grey01,
              }}
            >
              <StewartHeader />
              <Router routes={ROOT_ROUTES} />
            </Box>
            <StewartFooter />
          </Box>
        </AuthenticatedApp>
      </MsalAuthenticationTemplate>
    </>
  );
}

export default App;
