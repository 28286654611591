import { EditUnderwriterConfigurationDialogDto } from '@stewart/common-ui/dialogs/EditUnderwriterConfigurationDialog/models';
import {
  ActivateOrDeactivateCompanyDto,
  AddCompanyDto,
  BulkDeleteCompaniesDto,
  CompaniesAccessDto,
  CompanyListDto,
  CountryListDto,
  DeleteAllCompaniesDto,
  DeleteAllUsersFromCompanyDto,
  DistrictListDto,
  EditCompanyDto,
  RegionListDto,
  StateListDto,
} from '@stewart/common-ui/dialogs/ViewOrEditCompanyAccessDialog/models';
import { httpRequests } from '@stewart/core/http';
import {
  COUNT_USERS_API_PATH,
  CURRENT_BULK_INVITE_API_PATH,
  CURRENT_PROFILE_INFO_API_PATH,
  CURRENT_USER_INVITATION_API_PATH,
  CURRENT_USERS_API_PATH,
  USERS_SEARCH_API_PATH,
  GET_TEST_PATH,
  USERS_USERNAMES_API_PATH,
  USERS_SELECTED_USERNAMES_API_PATH,
  USERS_SETS_API_PATH,
  USER_METADATA_CACHE_API_PATH,
  USER_PATCH_API_PATH,
  USER_CURRENT_PERMISSION_ITEM_LIST_API_PATH,
  USER_ACTIVATE_OR_DEACTIVATE_API_PATH,
  USERS_DEFAULT_SETS_API_PATH,
  USER_DELETE_COMPANY_FROM_USER_API_PATH,
  USER_GET_COMPANIES_BY_USER_ID_API_PATH,
  USER_GET_COUNTRY_LIST,
  USER_GET_REGION_LIST,
  USER_GET_DISTRICT_LIST,
  USER_GET_STATE_LIST,
  USER_GET_COMPANY_LIST,
  USER_ASSIGN_OR_EDIT_COMPANY_FOR_USER,
  USER_BULK_ASSIGN_COMPANIES_FOR_USER,
  USER_BULK_DELETE_COMPANY_FROM_USER_API_PATH,
  GET_USER_TYPE_LIST_API_PATH,
  USERS_UWA_USER_CONFIG_API_PATH,
  USER_DELETE_ALL_COMPANIES_FROM_USER_API_PATH,
  USER_DELETE_ALL_USERS_FROM_COMPANY_API_PATH,
  USER_GET_COMPANIES_BY_USER_ID_DOWNLOAD_API_PATH,
  USERS_DOWNLOAD_API_PATH,
} from '@stewart/core/rest/api-paths';
import {
  GridRequestParamsType,
  GridResponseType,
  GridRequestWithColumnsFilterParamsType,
} from '@stewart/core/rest/models/grid.models';
import {
  DefaultPermissionSetDto,
  PermissionSetDto,
} from '@stewart/core/rest/models/permission.models';
import {
  CreateUserBody,
  ProfileDto,
  SendBulkInviteDto,
  CreateUserDto,
  CountUserDto,
  EditUserDto,
  GridUserBody,
  EditUserBody,
  EditStewartAccessUserNameType,
  NewStewartAccessUserNameType,
  DeleteStewartAccessUserNameType,
  StewartAccessUserNameValidType,
  UpdateUserPermissionSetBody,
  PatchUserBody,
  PermissionItemTypeReadModel,
  UserTypeListDto,
} from '@stewart/core/rest/models/user.models';

export function exportUsers(
  params: GridRequestParamsType | GridRequestWithColumnsFilterParamsType
): Promise<string> {
  return httpRequests.post(USERS_DOWNLOAD_API_PATH, { ...params });
}
/**
 * Return Current User Stewart Profile
 * @return {Promise<ProfileDto>}
 */
export function getProfile(): Promise<ProfileDto> {
  return httpRequests.get(CURRENT_PROFILE_INFO_API_PATH);
}

/**
 * Return All Users
 * @param {GridRequestParamsType} params
 * @return {Promise<GridUserBody[]>}
 */
export function getUsers(params: GridRequestParamsType): Promise<GridResponseType<GridUserBody>> {
  return httpRequests.post(USERS_SEARCH_API_PATH, params);
}

/**
 * Update and Return User Stewart Profile
 * @param {ProfileDto} data
 * @return {Promise<ProfileDto>}
 */
export function updateProfile(data: ProfileDto): Promise<ProfileDto> {
  return httpRequests.post(CURRENT_PROFILE_INFO_API_PATH, data);
}

/**
 * Get User by id
 * @param {string} id
 * @return {Promise<EditUserDto>}
 */
export function getUser(id: string): Promise<EditUserDto> {
  return httpRequests.get(`${CURRENT_USERS_API_PATH}/${id}`);
}

/**
 * Get Stewart Access Usernames by id
 * @param {EditUserDto} user
 * @return {Promise<EditStewartAccessUserNameType[]>}
 */
export function getStewartAccessUserNames({
  userId,
}: EditUserDto): Promise<EditStewartAccessUserNameType[]> {
  return httpRequests.get(`${USERS_USERNAMES_API_PATH.replace('{userId}', userId)}`);
}

/**
 * Add new Stewart Access Username
 * @param {EditUserDto} user
 * @param {NewStewartAccessUserNameType} newStewartAccessUserName
 * @return {Promise<EditStewartAccessUserNameType[]>}
 */
export function createStewartAccessUserName(
  { userId }: EditUserDto,
  newStewartAccessUserName: NewStewartAccessUserNameType
): Promise<EditStewartAccessUserNameType[]> {
  return httpRequests.post(
    `${USERS_USERNAMES_API_PATH.replace('{userId}', userId)}`,
    newStewartAccessUserName
  );
}

/**
 * Delete Stewart Access Username by accountId
 * @param {EditUserDto} user
 * @param {DeleteStewartAccessUserNameType} deleteStewartAccessUserNameType
 * @return {Promise<EditStewartAccessUserNameType[]>}
 */
export function deleteStewartAccessUserName(
  { userId }: EditUserDto,
  { accountID }: DeleteStewartAccessUserNameType
): Promise<EditStewartAccessUserNameType[]> {
  return httpRequests.remove(
    `${USERS_SELECTED_USERNAMES_API_PATH.replace('{userId}', userId).replace(
      '{accountId}',
      accountID.toString()
    )}`
  );
}

/**
 * Send Invitation for Create New User
 * @param {CreateUserBody} data
 * @return {Promise<CreateUserDto>}
 */
export function createUser(data: CreateUserBody): Promise<CreateUserDto> {
  return httpRequests.post(CURRENT_USER_INVITATION_API_PATH, data);
}

/**
 * Update User
 * @param {EditUserDto} data
 * @param {boolean} isForceUpdate
 * @return {Promise<StewartAccessUserNameValidType>}
 */
export function updateUser(
  data: EditUserBody,
  isForceUpdate: boolean = false
): Promise<StewartAccessUserNameValidType> {
  return httpRequests.put(`${CURRENT_USERS_API_PATH}/${data.userId}`, { ...data, isForceUpdate });
}
/**
 * Deactivate User
 * @param {EditUserDto} data
 * @returns  {Promise<StewartAccessUserNameValidType>}
 */
export function deactivateUser(data: EditUserDto): Promise<StewartAccessUserNameValidType> {
  return httpRequests.put(`${USER_ACTIVATE_OR_DEACTIVATE_API_PATH}/${data.userId}`, {
    userStatusTypeCode: 'INACTIVE',
  });
}

/**
//  * Activate User
//  * @param {EditUserDto} data
//  * @returns  {Promise<StewartAccessUserNameValidType>}
//  */
export function activateUser(data: EditUserDto): Promise<StewartAccessUserNameValidType> {
  return httpRequests.put(`${USER_ACTIVATE_OR_DEACTIVATE_API_PATH}/${data.userId}`, {
    userStatusTypeCode: 'ACTIVE',
  });
}

/**
 * Send Bulk Invite for Create New User
 * @param {CreateUserBody} data
 * @return {Promise<SendBulkInviteDto>}
 */
export function sendBulkInvite(data: CreateUserBody): Promise<SendBulkInviteDto> {
  return httpRequests
    .post(CURRENT_BULK_INVITE_API_PATH, data)
    .then((response: any) => ({ ...response, user: data }));
}

/**
 * Return Count of External and Internal Users
 * @return {Promise<CountUserDto>}
 */
export function getUsersCount(): Promise<CountUserDto> {
  return httpRequests.get(COUNT_USERS_API_PATH);
}

/**
 * Check API Working
 * @return {Promise<string>}
 */
export function checkAPIMethod(): Promise<string> {
  return httpRequests.get(GET_TEST_PATH);
}

/**
 * Get Permission Sets by userId
 * @param {string} userId
 * @return {Promise<PermissionSetDto[]>}
 */
export function getPermissionSetsByUser(userId: string): Promise<PermissionSetDto[]> {
  return httpRequests.get(`${USERS_SETS_API_PATH.replace('{userId}', userId)}`);
}

/**
 * Get Default Permission Sets by userTypeCode
 * @param {string} userTypeCode
 * @return {Promise<DefaultPermissionSetDto[]>}
 */
export function getDefaultPermissionSetsByUserTypeCode(
  userTypeCode: string
): Promise<DefaultPermissionSetDto[]> {
  return httpRequests.get(`${USERS_DEFAULT_SETS_API_PATH.replace('{userTypeCode}', userTypeCode)}`);
}

/**
 * Update Permission Sets for userId
 * @param {string} userId
 * @param {UpdateUserPermissionSetBody} updateUserPermissionSetBody
 * @return {Promise<void>}
 */
export function updateUserPermissionSet(
  userId: string,
  updateUserPermissionSetBody: UpdateUserPermissionSetBody
): Promise<void> {
  return httpRequests.put(
    `${USERS_SETS_API_PATH.replace('{userId}', userId)}`,
    updateUserPermissionSetBody
  );
}

/**
 * Remove Metadata by User
 * @param {EditUserBody} user
 * @return {Promise<void>}
 */
export function removeCacheByUser({ userId }: EditUserBody): Promise<void> {
  return httpRequests.remove(`${USER_METADATA_CACHE_API_PATH}`.replace('{userId}', userId));
}

/**
 * Fix User B2C
 * @param {PatchUserBody} data
 * @return {Promise<void>}
 */
export function fixB2CUser(data: PatchUserBody): Promise<void> {
  return httpRequests.put(USER_PATCH_API_PATH, data);
}

/**
 * Get Permission Items by current User
 * @return {Promise<PermissionItemTypeReadModel[]>}
 */
export function getPermissionItemsCurrentUser(): Promise<PermissionItemTypeReadModel[]> {
  return httpRequests.get(USER_CURRENT_PERMISSION_ITEM_LIST_API_PATH);
}

/**
 * Remove Company from User
 * @param {string} identityKey
 * @return {Promise<void>}
 */
export function deleteCompanyFromUser(identityKey: string): Promise<void> {
  return httpRequests.remove(
    USER_DELETE_COMPANY_FROM_USER_API_PATH.replace('{identityKey}', identityKey)
  );
}

/**
 * Bulk remove several companies from user
 * @param {BulkDeleteCompaniesDto} idsToDelete
 * @return {Promise<void>}
 */
export function bulkDeleteCompanyFromUser(idsToDelete: BulkDeleteCompaniesDto): Promise<void> {
  return httpRequests.post(USER_BULK_DELETE_COMPANY_FROM_USER_API_PATH, idsToDelete);
}

/**
 * Remove ALL Companies From User
 * @param {DeleteAllCompaniesDto} params
 * @return {Promise<void>}
 */

export function deleteAllCompaniesFromUser({
  userID,
  totalRows,
  showInactive,
}: DeleteAllCompaniesDto): Promise<void> {
  return httpRequests.post(
    USER_DELETE_ALL_COMPANIES_FROM_USER_API_PATH.replace('{userId}', userID),
    {
      showInactive,
      currentPage: 1,
      rowsPerPage: totalRows,
      sort: {
        column: 'identiyKey',
        direction: 'asc',
      },
    }
  );
}

/**
 * Remove ALL users from one Company
 * @param {DeleteAllUsersFromCompanyDto} params
 * @return {Promise<void>}
 */

export function deleteAllUsersFromCompany({
  companyID,
  totalRows,
}: DeleteAllUsersFromCompanyDto): Promise<void> {
  return httpRequests.post(USER_DELETE_ALL_USERS_FROM_COMPANY_API_PATH, {
    currentPage: 1,
    rowsPerPage: totalRows,
    companyID,
    sort: {
      column: 'userName',
      direction: 'desc',
    },
  });
}

/**
 * Get Companies from currentUser
 * @param {GridRequestParamsType} params
 * @param {string} userId
 * @return {Promise<GridResponseType<CompaniesAccessDto>>}
 */
export function getCompaniesByUserId(
  params: GridRequestParamsType,
  userId: string
): Promise<GridResponseType<CompaniesAccessDto>> {
  return httpRequests.post(USER_GET_COMPANIES_BY_USER_ID_API_PATH.replace('{userId}', userId), {
    ...params,
  });
}

/**
 *  Get Country List
 * @return {Promise<CountryListDto[]>}
 */
export function getCountryList(): Promise<CountryListDto[]> {
  return httpRequests.get(USER_GET_COUNTRY_LIST);
}

/**
 * Get Region List
 * @return {Promise<RegionListDto>}
 */
export function getRegionList(countryCodeNum: string): Promise<RegionListDto[]> {
  return httpRequests.get(`${USER_GET_REGION_LIST}/${countryCodeNum}`);
}

/**
 * Get District List
 * @return {Promise<DistrictListDto[]>}
 */
export function getDistrictList(
  countryCodeNum: string,
  region: string
): Promise<DistrictListDto[]> {
  return httpRequests.get(`${USER_GET_DISTRICT_LIST}/${countryCodeNum}/${region}`);
}

/**
 * Get State List
 * @return {Promise<StateListDto[]>}
 */
export function getStateList(
  countryCodeNum: string,
  region: string,
  district: string
): Promise<StateListDto[]> {
  return httpRequests.get(`${USER_GET_STATE_LIST}/${countryCodeNum}/${region}/${district}`);
}

/**
 * Get Company List
 * @return {Promise<CompanyListDto[]>}
 */
export function getCompanyList(
  countryCodeNum: string,
  region: string,
  district: string,
  stateCode: string,
  searchUser: string
): Promise<CompanyListDto[]> {
  return httpRequests.get(
    `${USER_GET_COMPANY_LIST}/${countryCodeNum}/${region}/${district}/${stateCode}/${searchUser}`
  );
}

/**
 * Assign / Edit Company for user
 * @param {AddCompanyDto | EditCompanyDto} params
 * @return {Promise<void>}
 */
export function assignOrEditCompanyForUser(
  params: AddCompanyDto | EditCompanyDto | ActivateOrDeactivateCompanyDto
): Promise<void> {
  return httpRequests.post(USER_ASSIGN_OR_EDIT_COMPANY_FOR_USER, params);
}

/**
 * Bulk assign companies for a User
 * @param {AddCompanyDto[]} listToAdd
 * @returns {Promise<void>}
 */

export function bulkAssignCompaniesForUser(listToAdd: AddCompanyDto[]): Promise<void> {
  return httpRequests.post(USER_BULK_ASSIGN_COMPANIES_FOR_USER, listToAdd);
}

/**
 * Return User Types List
 * @return {Promise<UserTypeListDto[]>}
 */
export function getApprovalStates(): Promise<UserTypeListDto[]> {
  return httpRequests.get(GET_USER_TYPE_LIST_API_PATH);
}

/**
 * Update Underwriter Configuration for user
 * @param {string} userId
 * @param {EditUnderwriterConfigurationDialogDto} data
 * @return {Promise<void>}
 */
export function saveEditUnderwriterConfiguration(
  userId: string,
  data: EditUnderwriterConfigurationDialogDto
): Promise<void> {
  return httpRequests.put(`${USERS_UWA_USER_CONFIG_API_PATH.replace('{userId}', userId)}`, data);
}

/**
 * Get Underwriter Configuration for user
 * @param {string} userId
 * @return {Promise<EditUnderwriterConfigurationDialogDto>}
 */
export function getEditUnderwriterConfiguration(
  userId: string
): Promise<EditUnderwriterConfigurationDialogDto> {
  return httpRequests.get(`${USERS_UWA_USER_CONFIG_API_PATH.replace('{userId}', userId)}`);
}

/**
 * Download Active companies or Active & Inactive
 * @param {GridRequestParamsType} params
 * @param {string} userID
 * @return {Promise<string>}
 */
export function downloadActiveAndInactiveCompanies(
  params: GridRequestParamsType,
  userID: string
): Promise<string> {
  return httpRequests.post(
    USER_GET_COMPANIES_BY_USER_ID_DOWNLOAD_API_PATH.replace('{userId}', userID),
    params
  );
}
