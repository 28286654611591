import { isArray, get } from 'lodash';

import { ObjectType } from '@stewart/core/models';

export const NONEXISTENT_TEXT: string = 'Label is Nonexistent';

const labels: ObjectType = {
  lbl_save: 'Save',
  lbl_ok: 'Ok',
  lbl_cancel: 'Cancel',
  lbl_item_description: 'Item Description',
  lbl_sub_type: 'SubType',
  lbl_sort_order: 'Sort Order',
  lbl_item_name: 'Item Name',
  lbl_item_code: 'Item Code',
  lbl_set_description: 'Set Description',
  lbl_set_name: 'Set Name',
  lbl_set_code: 'Set Code',
  lbl_create_user: 'Create User',
  lbl_login_type: 'Login Type',
  lbl_first_name: 'First Name',
  lbl_last_name: 'Last Name',
  lbl_email: 'Email',
  lbl_stewart_access_username: 'Stewart Access Username',
  lbl_user_type: 'User Type',
  lbl_user_time_zone: 'User Time Zone',
  lbl_new: 'New',
  lbl_next: 'Next',
  lbl_back: 'Back',
  lbl_details: 'Details',
  lbl_select_all: 'Select All',
  lbl_save_and_process: 'Save and Process',
  lbl_display_name: 'Display Name',
  lbl_edit_user: 'Edit User',
  lbl_user_details: 'User Details',
  lbl_email_username: 'Email (Username)',
  lbl_username_email: 'Username (Email)',
  lbl_user_status: 'User Status',
  lbl_last_login: 'Last Login',
  lbl_default: 'Default',
  lbl_yes: 'Yes',
  lbl_no: 'No',
  lbl_all: 'All',
  lbl_phone: 'Phone #',
  lbl_phone_number: 'Phone Number',
  lbl_deactivated_date: 'Deactivated Date',
  lbl_confirmation: 'Confirmation',
  lbl_confirm: 'Confirm',
  lbl_discard: 'Discard',
  lbl_error: 'Error',
  lbl_success: 'Success',
  lbl_successful: 'Successful',
  lbl_healthy: 'Healthy',
  lbl_export: 'Export',
  lbl_actions: 'Actions',
  lbl_users: 'Users',
  lbl_permissions: 'Permissions',
  lbl_profile_settings: 'Profile Settings',
  lbl_logout: 'Logout',
  lbl_select: 'Select',
  lbl_internal: 'Internal',
  lbl_external: 'External',
  lbl_view: 'View',
  lbl_view_new: 'View New',
  lbl_info: 'Info',
  lbl_edit: 'Edit',
  lbl_fix: 'Fix',
  lbl_upload_new_users: 'Upload New Users',
  lbl_b2c_use_id: 'B2C User ID',
  lbl_db_use_id: 'DB User ID',
  lbl_cache: 'Cache',
  lbl_grid_cache: 'Metadata Cache Management',
  lbl_sc_user: 'SC User',
  lbl_sc_user_id: 'SC User ID',
  lbl_sa_user: 'SA User',
  lbl_state: 'State',
  lbl_version: 'Version',
  lbl_company: 'Company',
  lbl_as_of_date: 'As Of Date',
  lbl_metadata_date: 'Metadata Date',
  lbl__exp_date: 'Expiration Date',
  lbl_date: 'Date',
  lbl_traces: 'Traces',
  lbl_exceptions: 'Exceptions',
  lbl_requests: 'Requests',
  lbl_type: 'Type',
  lbl_message: 'Message',
  lbl_download: 'Download',
  lbl_copy: 'Copy',
  lbl_cloud_logs: 'Cloud Logs',
  lbl_link: 'Link',
  lbl_description: 'Description',
  lbl_clear_cache: 'Clear Metadata Cache',
  lbl_clear_redis_cache: 'Clear Redis Cache',
  lbl_delete: 'Delete',
  lbl_reload: 'Reload',
  lbl_logs: 'Logs',
  lbl_external_logs: 'External System Request Log',
  lbl_New: 'NEW',
  lbl_Archive: 'ARCHIVE',
  lbl_internal_logs: 'Internal System Request Log',
  lbl_changeset_logs: 'Changeset Log',
  lbl_request_uri: 'URL',
  lbl_request_uri_name: 'URL Name',
  lbl_related_file: 'Related File',
  lbl_sc_file_id: 'SC File Id',
  lbl_related_order: 'Related Order',
  lbl_sc_order_id: 'SC Order Id',
  lbl_file_details: 'File Details for $fileNumber',
  lbl_file_number: 'File Number',
  lbl_request_timestamp: 'Request Timestamps',
  lbl_response_timestamps: 'Response Timestamps',
  lbl_request_version: 'Request Version',
  lbl_request_method: 'Request Method',
  lbl_request_format: 'Request Format',
  lbl_external_user: 'External User',
  lbl_related_agency: 'Related Agency',
  lbl_request_xml: 'Request XML',
  lbl_response_xml: 'Response XML',
  lbl_response_json: 'Response JSON',
  lbl_response_pdf: 'Response PDF',
  lbl_changeset_data_xml: 'Changeset Data XML',
  lbl_changeset_fields_xml: 'Changeset Fields XML',
  lbl_server_name: 'Server Name',
  lbl_image_name: 'Image Name',
  lbl_type_tables: 'Type Tables',
  lbl_select_type_tables: 'Select Type Table',
  lbl_create_new_item: 'Create New Item',
  lbl_customer: 'Customer',
  lbl_asm: 'ASM',
  lbl_asr: 'ASR',
  lbl_support: 'Support',
  lbl_uwa_user: 'UWAUser',
  lbl_active: 'Active',
  lbl_inactive: 'Inactive',
  lbl_companies: 'Companies',
  lbl_configuration: 'Configuration',
  lbl_file_management: 'File Management',
  lbl_general_data: 'General  Data',
  lbl_transmittal_errors: 'Transmittal Errors',
  lbl_transmittal_error_description: 'Transmittal Error Description',
  lbl_issuing_state: 'Issuing State',
  lbl_county_code: 'County Code',
  lbl_trans_code: 'Trans Code',
  lbl_select_trans_code: 'Select Trans Code',
  lbl_select_county: 'Select County',
  lbl_select_legacy_id: 'Select LegacyID',
  lbl_trans_code_desc: 'Trans Code Description',
  lbl_trans_type: 'Transaction Type',
  lbl_policy_number: 'Policy Number',
  lbl_policy_date: 'Policy Date',
  lbl_product: 'Product',
  lbl_covered_party_formtype: 'Covered Party / Form Type',
  lbl_product_sub_type: 'Product Sub Type',
  lbl_liability: 'Liability',
  lbl_gross_premium: 'Gross Premium',
  lbl_uw_retention: 'UW Retention',
  lbl_underwriter: 'Underwriter',
  lbl_issue_date: 'Issue Date',
  lbl_void_date: 'Void Date',
  lbl_file_num: 'File',
  lbl_internal_file: 'Internal File Number',
  lbl_total_jacket_liability: 'Total Jacket Liability',
  lbl_simultaneous: 'Simultaneous',
  lbl_reissue: 'Reissue',
  lbl_uw_requests: 'UW Requests',
  lbl_new_request: 'New Request',
  lbl_request_details: 'Request Details',
  lbl_grid_uw_request: 'Underwriter Requests',
  lbl_request_info: 'Request Information',
  lbl_file: 'File #',
  lbl_UW_sub_type: 'Sub-Type',
  lbl_request: 'Request #',
  lbl_approval_state: 'Approval State',
  lbl_agency: 'Agency',
  lbl_agency_name: 'Agency Name',
  lbl_priority: 'Priority',
  lbl_owner: 'Owner',
  lbl_starter: 'Starter',
  lbl_group: 'Group',
  lbl_summary: 'Summary',
  lbl_policy: 'Policy #',
  lbl_policy_type: 'Policy Type',
  lbl_approval_level: 'Approval Level',
  lbl_project_reference: 'Project Name/Reference',
  lbl_requested_amount: 'Requested Amount',
  lbl_date_received: 'Date Received',
  lbl_approved_amount: 'Approved Amount',
  lbl_title_examined_from: 'Title Examined From',
  lbl_title_examined_to: 'Title Examined To',
  lbl_agency_id: 'Agency ID',
  lbl_agency_type: 'Agency Type',
  lbl_agency_address: 'Agency Address',
  lbl_requestor: 'Requestor',
  lbl_authority_limit: 'Authority Limit',
  lbl_fax: 'Fax Number',
  lbl_re_enter_email: 'Re-enter Email',
  lbl_original_approved_amount: 'Original Approved Amount',
  lbl_original_approved_date: 'Original Approved Date',
  lbl_revision_info: 'Revision Information',
  lbl_cancellation_date: 'Cancellation Date',
  lbl_cancelled_by: 'Cancelled By',
  lbl_cancellation_reason: 'Cancellation Reason',
  lbl_purpose_of_financing: 'Describe Transaction / Purpose of Financing',
  lbl_description_of_property_type: 'Description of property type',
  lbl_prior_company: 'Starter / Prior Company',
  lbl_prior_effective_date: 'Prior effective date',
  lbl_prior_policy_type: 'Prior Policy Type',
  lbl_land_about_open_dedicated_road: 'Land about open/dedicated road',
  lbl_was_title_to_access_easement_examined: 'Was title to access easement examined',
  lbl_insured_lien_based_on_subordination_agreement:
    'Insured lien based on subordination agreement',
  lbl_authority_of_seller_borrower: 'Authority of seller / borrower',
  lbl_proof_of_payment: 'Proof of payment',
  lbl_notice_of_completion_filed: 'Notice of completion filed',
  lbl_conflict_of_interest: 'Conflict of Interest',
  lbl_any_issuing_agents_owing_more_than_10: 'Any Issuing Agents owing more than 10%',
  lbl_policy_will_be_issued_by: 'Policy will be issued by',
  lbl_list_of_state_endorsements: 'List of State Endorsements',
  lbl_co_insurers_liability_amt_or_percent: 'Co-Insurers / Liability Amt or %',
  lbl_construction_loan: 'Construction Loan',
  lbl_broken_priority_or_no_priority: 'Broken-priority (e.g. early start) or no-priority',
  lbl_describe_underwriting: 'Describe Underwriting',
  lbl_recent_construction_performed_or_completed_within_the_lien_period:
    'Recent construction performed or completed within the lien period',
  lbl_request_for_mechanics_lien_coverage: 'Request for Mechanic’s Lien Coverage',
  lbl_request_for_mechanics_lien_coverage_note:
    'NOTE: If this transaction is a construction loan and if the total project cost (i.e.the loan amount plus other contributions) is equal to or greater than $40,000,000, please also complete STG HighLiability (Over $40 Million) Mechanic’s Lien Coverage Approval Request',
  lbl_lien_priority_based_upon_subordination_of_a_lien_or_mortgage:
    'Lien Priority based upon subordination of a lien or mortgage',
  lbl_tidelands_filled_land_submerged_land_navigable_waters_or_riparian_issues:
    'Tidelands, filled land, submerged land, navigable waters or riparian issues',
  lbl_issuing_title_to_railroad_property: 'Issuing title to railroad property',
  lbl_title_based_upon_judicial_proceedings:
    'Title based upon judicial proceedings (e.g. tax foreclosure, condemnation, bankruptcy)',
  lbl_title_based_upon_foreclosure_or_deed_in_lieu_of_foreclosure:
    'Title based upon foreclosure or deed in lieu of foreclosure',
  lbl_title_derived_from_foreclosure_or_deed_in_lieu_of_foreclosure_regarding_a_construction_loan_deed_of_trust_within_the_last_three_years:
    'Title derived from foreclosure or deed in lieu of foreclosure regarding a construction loan deed of trust, within the last three years',
  lbl_easement_independent_of_real_property:
    'Easement independent of real property (i.e. an easement in gross)',
  lbl_insured_option: 'Insured option',
  lbl_native_american_lands: 'Native American (Indian) lands',
  lbl_water_rights: 'Water rights',
  lbl_sheriffs_sale_in_the_last_10_years:
    'Sheriff’s Sale in the last 10 years (other than mortgage foreclosure)',
  lbl_assignment_or_partial_assignment_or_mortgage_of_lease_easement_or_other_interest:
    'Assignment or partial assignment or mortgage of lease, easement, or other interest',
  lbl_describe_consent_approvals_to_be_obtained: 'Describe consent / approvals to be obtained',
  lbl_reliance_upon_an_indemnity: 'Reliance upon an indemnity',
  lbl_describe_purpose: 'Describe Purpose',
  lbl_insuring_around_a_recorded_lien_or_encumbrance:
    'Insuring around a recorded lien or encumbrance (e.g. by omitting, deleting or providing affirmative insurance)',
  lbl_survey_concerns:
    'Survey concerns (e.g. does the survey show any significant conflict or encroachment)',
  lbl_current_owner_out_of_possession_of_the_property:
    'Current owner out of possession of the property',
  lbl_other_extra_hazardous_risks: 'Other extra hazardous risks, such as those shown in',
  lbl_describe_extra_hazardous_risks: 'Describe extra hazardous risks',
  lbl_special_risk_description: 'Special risk description',
  lbl_unusual_risk_issues_affirmative_coverages: 'Unusual risk / issues / affirmative coverages',
  lbl_equal_to_or_over_30_million: 'Equal to or over 30 Million',
  lbl_policy_form: 'Policy Form',
  lbl_proposed_insured: 'Proposed Insured',
  lbl_amount: 'Amount',
  lbl_manage_signatures: 'Manage Signatures',
  lbl_cpl_cancellation_letter: 'CPL Cancellation Letter',
  lbl_signatures: 'Signatures',
  lbl_company_id: 'Company ID',
  lbl_company_name: 'Company Name',
  lbl_sig_state: 'Sig State',
  lbl_state_list: 'State List',
  lbl_location_id: 'Location ID',
  lbl_legacy_id: 'Legacy ID',
  lbl_signature_name: 'Signature Name',
  lbl_signature_description: 'Signature Description',
  lbl_image_format: 'Image Format',
  lbl_upload_signature: 'Upload Signature',
  lbl_edit_signature: 'Edit Signature',
  lbl_grid_company: 'Companies',
  lbl_legal_name: 'Legal Name',
  lbl_address_1: 'Address 1',
  lbl_address_2: 'Address 2',
  lbl_city: 'City',
  lbl_zip: 'Zip',
  lbl_company_legacy_id: 'Company Legacy ID',
  lbl_active_contract_id: 'Active Contract ID',
  lbl_company_type: 'Company Type',
  lbl_status: 'Status',
  lbl_sub_status: 'Sub Status',
  lbl_upload: 'Upload',
  lbl_from: 'From',
  lbl_to: 'To',
  lbl_apply: 'Apply',
  lbl_reset: 'Reset',
  lbl_client_file_id: 'Client File ID',
  lbl_view_image: 'View Image',
  lbl_activate: 'Activate',
  lbl_deactivate: 'Deactivate',
  lbl_clear: 'Clear',
  lbl_table_name: 'Table Name',
  lbl_operation_type: 'Operation Type',
  lbl_new_row_id: 'New Row ID',
  lbl_changeset_date: 'Changeset Date',
  lbl_user_name: 'User Name',
  lbl_help_center: 'Help Center',
  lbl_file_type: 'File Type',
  lbl_file_PDF: 'PDF',
  lbl_file_MP4: 'MP4',
  lbl_file_name: 'File Name',
  lbl_display_place: 'Display Place',
  lbl_CPL_AAL: 'CPLs/AALs',
  lbl_CPL_AAL_full_name: 'Closing Protection Letters(CPLs) / Agent Authorization Letters(AALs)',
  lbl_JACKET: 'Jackets',
  lbl_PRICING: 'Pricing',
  lbl_GENERAL: 'General',
  lbl_STANDALONE: 'Standalone Endorsements',
  lbl_REPORT_PAY: 'Reporting & Payments',
  lbl_tags: 'Tags',
  lbl_cpl: 'CPLs/AALs',
  lbl_training_material: 'Training Materials',
  lbl_release: 'Release Notes',
  lbl_faqs: 'FAQs',
  lbl_submit_ticket: 'Submit A Ticket',
  lbl_add_item: 'Add New Item',
  lbl_username: 'Username',
  lbl_view_users: 'View Users',
  lbl_view_user_details: 'View User Details',
  lbl_type_table_name: 'Type Table: $typeTable',
  lbl_close: 'Close',
  lbl_release_notes: 'Release Notes',
  lbl_release_name: 'Release Name',
  lbl_view_sample: 'View Sample',
  lbl_active_users: 'Active Users',
  lbl_active_agents: 'Active Agents',
  lbl_new_files: 'New Files',
  lbl_new_products: 'New Products',
  lbl_migrated_files: 'Migrated Files',
  lbl_select_basic_period: 'Select Basic Counts Period',
  lbl_select_files_by_state_period: 'Select Files By State Period',
  lbl_select_product_summary_period: 'Select Product Summary Period',
  lbl_select_product_summary_by_state_period: 'Select Product Summary By State Period',
  lbl_period: 'Period',
  lbl_refresh: 'Refresh',
  lbl_approval_information: 'Approval Information',
  lbl_more_info: 'More Info',
  lbl_response_status: 'Response Status',
  lbl_response_message: 'Response Message',
  lbl_request_type: 'Request Type',
  lbl_request_id: 'Request ID',
  lbl_request_batch_id: 'Request Batch ID',
  lbl_internal_request_type: 'Internal Request Type',
  lbl_client_id: 'Client ID',
  lbl_support_id: 'Support ID',
  lbl_integration_request_log: 'Integration System Request Log',
  lbl_tps_integration_request_log: 'TPS Integration Request Log',
  lbl_external_ref_id: 'Related Ref ID',
  lbl_related_agency_name: 'Related Agency Name',
  lbl_related_agency_id: 'Related Agency Legacy ID',
  lbl_serial_number: 'Serial Number',
  lbl_property_state: 'Property State',
  lbl_tps: 'TPS',
  lbl_product_type: 'Product Type',
  lbl_product_action: 'Product Action',
  lbl_attachments: 'Attachments',
  lbl_attachment_name: 'Attachment Name',
  lbl_attachment_name_title: 'Attachment Name (Title)',
  lbl_size_bytes: 'Size (In Bytes)',
  lbl_modified: 'Modified',
  lbl_update_file: 'Update File',
  lbl_comments: 'Comments',
  lbl_view_file: 'View File',
  lbl_upload_file: 'Upload File',
  lbl_add_url: 'Add URL',
  lbl_view_url: 'View URL',
  lbl_url: 'URL',
  lbl_add_company: 'Add Company',
  lbl_assign_company: 'Assign Company',
  lbl_country: 'Country',
  lbl_region: 'Region',
  lbl_district: 'District',
  lbl_edit_company: 'Edit Company',
  lbl_assigned_companies: 'Assigned Companies',
  lbl_property: 'Property',
  lbl_new_property: 'New Property',
  lbl_property_type: 'Property Type',
  lbl_address: 'Address',
  lbl_state_province: 'State / Province',
  lbl_postal_code: 'Postal Code',
  lbl_counter_parish: 'County / Parish',
  lbl_municipal_code: 'Municipal Code',
  lbl_lot: 'Lot',
  lbl_block: 'Block',
  lbl_section: 'Section',
  lbl_subdivision: 'Subdivision',
  lbl_pin: 'Property ID Number (PIN)',
  lbl_brief_legal_description: 'Brief Legal Description',
  lbl_property_details: 'Property Details',
  lbl_approvals: 'Approvals',
  lbl_approved_rejected_by: 'Approved/Rejected By',
  lbl_approved_rejected: 'Approved/Rejected',
  lbl_approval_rejector: 'Select Approval/Rejector',
  lbl_view_edit_user_company_access_title: 'View / Edit {userName} Company Access',
  lbl_edit_user_permissions: 'Edit {userName} User Type and Permissions',
  lbl_add_missing_default_permissions: 'Add Missing Default Permissions',
  lbl_reset_to_default_permissions: 'Reset to Default Permissions',
  lbl_conditions: 'Conditions',
  lbl_new_condition: 'New Condition',
  lbl_item_preview: 'Item Preview',
  lbl_condition_details: 'Condition Details',
  lbl_start: 'Start',
  lbl_end: 'End',
  lbl_due: 'Due',
  lbl_done: 'Done',
  lbl_duration: 'Activity Duration in Min',
  lbl_notes: 'Notes',
  lbl_new_note: 'New Note',
  lbl_view_note: 'View Note',
  lbl_date_created: 'Date Created',
  lbl_created_by: 'Created By',
  lbl_note_type: 'Note Type',
  lbl_note: 'Note Text',
  lbl_new_related_request: 'New Related Request',
  lbl_unlink_related_request: 'Unlink Related Requests',
  lbl_related_requests: 'Related Requests',
  lbl_contacts: 'Contacts',
  lbl_new_contact: 'New Contact',
  lbl_view_contact: 'View Contact',
  lbl_mr_ms: 'Mr/Ms',
  lbl_suffix: 'Suffix',
  lbl_job_title: 'Job Title',
  lbl_work_number: 'Work #',
  lbl_primary_email_address: 'Primary Email Address',
  lbl_delete_selected: 'Delete Selected',
  lbl_view_users_comapny: 'View Users For $company Company',
  lbl_remove_selected_users: 'Remove Selected Users',
  lbl_remove_selected_companies: 'Remove Selected Companies',
  lbl_add_branch: 'Add Branch',
  lbl_view_branches: 'View Branches',
  lbl_branch_name: 'Branch Name',
  lbl_view_branches_for_company: 'View Branches For $companyID Company',
  lbl_delete_branch: 'Delete Branch',
  lbl_cancellation: 'Cancellation',
  lbl_underwriter_type: 'Underwriter Type',
  lbl_underwriter_group: 'Underwriter Group',
  lbl_remove_ALL_companies: 'Remove All $number Companies',
  lbl_remove_ALL_users: 'Remove All Users',
  lbl_view_locations: 'View Locations',
  lbl_view_locations_for_branch: 'View Locations For $branchID Branch',
  lbl_location_name: 'Location',
  lbl_add_location: 'Add Location',
  lbl_delete_location: 'Delete Location',
  lbl_userID: 'User ID',
  lbl_displayName: 'Display Name',
  lbl_metadata_user: 'Metadata User',
  lbl_effective_date: 'Effective Date',
  lbl_agency_count: 'Agency Count',
  lbl_gen_time: 'Gen Time (s)',
  lbl_special_risks: 'Special Risks',
  lbl_additional_info: 'Additional Information',
  lbl_agency_info: 'Agency Information',
  lbl_cancellation_info: 'Cancellation Information',
  lbl_project_name: 'Project Name',
  lbl_add_contact: '+ Add Contact',
  lbl_name: 'Name',
  lbl_if_yes_describe_risk: 'If YES, please describe risk here',
  lbl_insuring_title: 'Insuring title to railroad property',
  lbl_mineral_coverage: 'Mineral coverage on commercial property in area of mineral development',
  lbl_any_purchase_outstanding_in_favor_of_proposed_insured:
    'Any purchase contract(s) or option(s) outstanding including right of first refusal, right of first offer, other than the purchase contract in favor of the proposed insured',
  lbl_current_or_pending_litigation: 'Current, recent or impending litigation',
  lbl_transaction_turned_down_by_other_uw:
    'A transaction that, to your knowledge, has been turned down by another Underwriter',
  lbl_enerygy_project:
    'An energy project (e.g., wind, solar, geothermal, hydro, etc., including conventional) and/or any energy endorsement(s)',
  lbl_cannabis: 'Cannabis',
  lbl_hard_money: 'Hard Money',
  lbl_other_unsual_risks: 'Other unusual risks, issues and/or affirmative coverages',
  lbl_co_insurance: 'Co-insurance',
  lbl_add_property: '+ Add Property',
  lbl_property_numbered: 'Property $number',
  lbl_approval_numbered: '$approvalName $number',
  lbl_add_note: '+ Add Note',
  lbl_first_approval: 'First Approval',
  lbl_additional_approval: 'Additional Approval',
  lbl_add_approval: '+ Add Approval',
  lbl_uw_approval_items: 'UW Approval Items',
  lbl_show_inactive: 'Show Inactive',
  lbl_view_contracts: 'View Contracts',
  lbl_contracts_for_company: 'Contracts for $legalName ($companyID/$legacyID) [$nonIssuingAgent]',
  lbl_contract_number: 'Contract Number',
  lbl_contract_id: 'Contract ID',
  lbl_contract_sub_type: 'Contract Sub Type',
  lbl_premium_calculation_type: 'Premium Calculation Type',
  lbl_split_type: 'Split Type',
  lbl_remit_percent: 'Remit Percent',
  lbl_min_remit_per_thousand: 'Min Remit Per Thousand',
  lbl_remit_per_thousand: 'Remit Per Thousand',
  lbl_endorsement_remit_per_send: 'Endorsement Remit Percent',
  lbl_endorsement_remit_per_thoundsand: 'Endorsement Remit Per Thousand',
  lbl_endorsement_split_type: 'Endorsement Split Type',
  lbl_haz_risk_endorsement_split_type: 'Haz Risk Endorsement Split Type',
  lbl_haz_risk_endorsement_remit_percent: 'Haz Risk Endorsement Remit Percent',
  lbl_haz_risk_endorsement_remit_per_thousand: 'Haz Risk Endorsement Remit Per Thousand',
  lbl_lender_cpl_fee: 'Lender CPL Fee',
  lbl_buyer_cpl_fee: 'Buyer CPL Fee',
  lbl_seller_cpl_fee: 'Seller CPL Fee',
  lbl_cpl_remit_percent: 'CPL Remit Percent',
  lbl_location_unique_id: 'Location Unique ID',
  lbl_alta_id: 'ALTA ID',
  lbl_location_display_name: 'Location Display Name',
  lbl_valid: 'Valid',
  lbl_list_of_company_contracts: 'List of Company Contracts',
  lbl_list_of_contract_territories: 'List of Contract Territories',
  lbl_list_of_territory_locations: 'List of Territory Locations',
  lbl_division: 'Division',
  lbl_add_contact_name: 'Add Contact Name',
  lbl_add_email_address: 'Add Email Address',
  lbl_add_amount: 'Add Amount',
  lbl_add_policy_form: 'Add Policy Form',
  lbl_add_policy_type: 'Add Policy Type',
  lbl_add_proposed_insured: 'Add Proposed Insured',
  lbl_related: 'Related',
  lbl_add_related_request: 'Add Related Request',
  lbl_request_amt: 'Request AMT',
  lbl_modified_by: 'Modified By',
  lbl_date_modified: 'Modified Date',
  lbl_add_attachment: '+ Add Attatchment',
  lbl_date_added: 'Date added',
  lbl_date_updated: 'Date Updated',
  lbl_date_created_by: 'Date Created By',
  lbl_date_updated_by: 'Updated By',
  lbl_add_policy: '+ Add Policy',
  lbl_insureing_lease_or_mortage:
    'Insuring a lease and/or a mortgage encumbering a lease. If YES, describe consents/estoppels below and please attach a copy of the lease',
  lbl_mecanic_liens_approva_request: 'Mechanic’s Lien Coverage Approval Request',
  lbl_VU_uw_manual_section_8_12: 'VU Underwriting Manual Section 8.12',
  lbl_VU_uw_manual_section_5_36: 'VU Underwriting Manual Section 5.36',
  lbl_has_survey_concerns: 'Survey Concerns',
  lbl_reliance_on_indemnity: 'Reliance upon an indemnity',
  lbl_find: 'Find',
  lbl_starting_with: 'Starting with',
  lbl_search: 'Search',
  lbl_contains: 'Contains',
  lbl_cancel_request: 'Cancel Request',
  lbl_decline_request: 'Decline Request',
  lbl_declined_by: 'Declined By',
  lbl_declination_date: 'Declination Date',
  lbl_declination_reason: 'Declination Reason',
  lbl_declination_info: 'Declination Information',
  lbl_revise: 'Revise',
  lbl_revision_date: 'Revision Date',
  lbl_revision_notes: 'Revision Reason/Notes',
  lbl_revised_by: 'Revised By',
  lbl_file_notes: 'File Notes',
  lbl_requestor_name: 'Requester Name',
  lbl_requestor_email: 'Requester Email',
  lbl_enter_notes: 'Enter Notes',
  lbl_about_record: 'About Record',
  lbl_continue: 'Continue',
  lbl_columns: 'Columns',
  lbl_all_columns: 'All columns',
  lbl_hide_all: 'Hide All',
  lbl_show_all: 'Show All',
  lbl_delete_request: 'Delete Request',
  lbl_document_name: 'Document Name',
  lbl_document_source_type: 'Document Source Type',
  lbl_document_status_type: 'Document Status Type',
  lbl_document_created_by: 'Document Created By',
  lbl_document_created_date: 'Document Created Date',
  lbl_file_documents: 'File Documents',
  lbl_file_documents_fileID: 'File Documents for $fileNumber',
  lbl_property_address: 'Property Address',
  lbl_lender_names: 'Lender',
  lbl_buyer_names: 'Buyer/Borrower',
  lbl_seller_names: 'Seller',
  lbl_open_date: 'Opened',
  lbl_agency_location: 'Agency Location',
  lbl_amended_ImageName: 'Amended Image Name',
  lbl_uploadDateTime: 'Upload Date/Time',
  lbl_products: 'Products',
  lbl_product_name: 'Product Name',
  lbl_product_effective_date: 'Product Effective Date',
  lbl_product_issue_date: 'Product Issue Date',
  lbl_product_revision_date: 'Product Revision Date',
  lbl_product_voided_date: 'Product Voided Date',
  lbl_re_insurance_required: 'Re-Insurance required',
  lbl_re_insurance_field: 'If YES, list Breakdowns and Premiums.',
  lbl_multi_state_required: 'Multi-State',
  lbl_please_describe: 'If YES, Please describe.',
  lbl_multi_site_required: 'Multi-Site',
  lbl_companies_percentages: 'If YES, what Companies and Percentages?',
  lbl_aggregate_amount_required: 'Aggregate Amount',
  lbl_co_insurance_required: 'Co-insurance',
  lbl_suc: 'SUC',
  lbl_uac: 'UAC',
  lbl_closing_date: 'Closing Date',
  lbl_anticipated_closing_date: 'Anticipated Closing Date',
  lbl_add_new_row: '+ Add New Row',
  lbl_auto_report_settings: 'Auto-Report Settings',
  lbl_policy_upload: 'Uploads',
  lbl_uploadedBy: 'Uploaded By',
  lbl_policy_portal: 'Policy Services Portal',
  lbl_connect_portal: 'Connect Portal',
};

/**
 * Return Label by Tag or Tag List
 * @param {string | string[]} tag
 * @return {string}
 */
export function label(tag: string | string[]): string {
  if (isArray(tag)) {
    return tag.length
      ? tag.reduce((text: string, item: string): string => `${text} ${get(labels, item, '')}`, '')
      : NONEXISTENT_TEXT;
  }

  return get(labels, tag, NONEXISTENT_TEXT);
}
