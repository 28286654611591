import { isArray, get } from 'lodash';

import { ObjectType } from '@stewart/core/models';

const NONEXISTENT_TEXT: string = 'Message is Nonexistent';

const messages: ObjectType = {
  msg_edit_user_permissions: 'View / Edit User Type and Permissions',
  msg_permission_sets: 'Permission Sets',
  msg_new_permission_item: 'New Permission Item',
  msg_permission_set_name: 'Permission Set Name',
  msg_permission_set_description: 'Permission Set Description',
  msg_new_permission_set: 'New Permission Set',
  msg_create_new_permission_item: 'Create New Permission Item',
  msg_permission_item_description: 'Permission Item Description',
  msg_permission_item_name: 'Permission Item Name',
  msg_already_exists_in_b2c_error_text:
    '$firstName $lastName $email already exists in B2C. Are you sure that you want to proceed and add this user to Stewart Connect DataBase?',
  msg_edit_stewart_access_username: 'Edit Stewart Access Username',
  msg_are_you_sure_to_delete: 'Are you sure you want to delete $userName?',
  msg_edit_uploaded_csv_file: 'Edit Uploaded File',
  msg_users_were_not_added: 'users were not added',
  msg_users_successfully_added: 'users were successfully added',
  msg_existing_b2c_users: 'Existing B2C Users',
  msg_validation_errors: 'Validation Errors',
  msg_select_those_users_be_added: 'Select those users that should be added.',
  msg_the_following_users_already_exist_in_b2c:
    'The following users already exist in B2C. Are you sure that you want to proceed and add these users to Stewart Connect DataBase?',
  msg_permission_items: 'Permission Items',
  msg_profile_settings: 'Profile Settings',
  msg_are_you_sure_update_user_with_invalid_stewart_access:
    'Are you sure want to update user with invalid Stewart Access Username: $userName?',
  msg_default_confirm_dialog_message:
    'You have unsaved changes. You can discard your changes, or cancel to continue editing.',
  msg_stewart_title_guaranty_company: 'Stewart Title Guaranty Company. All Rights Reserved.',
  msg_trademarks_are_the_property_of_their_respective_owners:
    'Trademarks are the property of their respective owners.',
  msg_privacy_policy: 'Privacy Policy',
  msg_protecting_customer_information: 'Protecting Customer Information',
  msg_terms_of_use: 'Terms of Use',
  msg_no_records: 'No records',
  msg_no_records_filters:
    'No filters have been entered. Please enter a filter and click Apply button.',
  msg_no_records_search: 'Please enter a search criteria and click Search button.',
  msg_no_records_faq: 'No FAQs have been uploaded. Please click Add New Item to add FAQ.',
  msg_no_records_release_notes:
    'No release notes have been uploaded. Please click Upload to add a release note.',
  msg_no_records_training_materials:
    'No training materials have been uploaded to the $tabName tab. Please click Upload to add training materials.',
  msg_stewart_external_and_internal_users_in_admin_portal:
    'Stewart External and Internal Users in Admin Portal',
  msg_percentage_of_charts_which_looks_like_pacman: 'Percentage of charts which looks like Pac-man',
  msg_are_you_sure_want_delete_permission_set_name:
    'Are you sure want to delete $permissionSetName Permission Set? This could potentially affect multiple users who have the same Permission Set.',
  msg_please_add_required_information: 'Please add required information',
  msg_sorry_you_can_not_upload_more_than_100_users_in_one_file:
    "Sorry you can't upload more than 100 users in one file",
  msg_please_enter_valid_email: 'Please enter valid Email',
  msg_field_is_required: 'Field is required',
  msg_the_field_can_not_include_leading_and_trailing_spaces:
    "The field can't include leading and trailing spaces",
  msg_are_you_sure_want_to_clear_all_metadata_cache:
    'Are you sure you want to clear Metadata Cache for all users?',
  mag_are_you_sure_you_want_to_delete_this_cache_row:
    'Are you sure you want to delete this Cache row?',
  msg_cache_details: 'Cache details',
  msg_clear_metadata_cache: 'Clear Metadata Cache',
  msg_view_metadata_cache: 'View Metadata Cache',
  mag_are_you_sure_you_want_to_clear_all_metadata_cache_for_user:
    'Are you sure you want to clear all Metadata cache for $userName user?',
  msg_details_are_not_available: 'Details are not available',
  msg_edit_permissions_allowed_for_a_user_type: 'Edit Permissions Allowed for a User Type',
  msg_external_log_details: 'External Log Details',
  msg_internal_log_details: 'Internal Log Details',
  msg_integration_log_details: 'Integration Log Details',
  msg_changeset_log_details: 'Changeset Log Details',
  msg_cache_details_export: 'Cache Details',
  msg_new_type_item: 'New Type Item',
  msg_are_you_sure_want_delete_type_item: 'Are you sure you want to delete $typeName Item?',
  msg_please_enter_whole_value_to_search_for_DB_User_ID:
    'Please, enter the whole value to search for DB User ID',
  msg_please_enter_whole_value_to_search_for_request_batch_ID:
    'Please, enter the whole value to search for Request Batch ID',
  msg_edit_type_item: '$itemName Item',
  msg_are_you_sure_you_want_to_clear_redis_cache: 'Are you sure you want to clear Redis Cache?',
  msg_are_you_sure_want_delete_signature:
    'Are you sure you want to delete $signatureName Signature?',
  msg_sorry_your_file_has_wrong_format:
    'Sorry your file has wrong format. Please upload the image in a format selected in Image Format dropdown.',
  msg_image_is_required: 'Image is required',
  msg_drag_and_drop_file: 'Drag & Drop file here or click to upload',
  msg_drag_and_drop_files: 'Drag & Drop file(s) here or click to upload',
  msg_drag_and_drop_supported_formats_1: 'Supported formats: JPG, JPEG, PNG',
  msg_drag_and_drop_supported_formats_2: 'Size limit: 50MB',
  msg_only_numbers_allowed: 'Sorry, only numbers allowed',
  msg_are_you_sure_want_activate_signature:
    'Are you sure you want to activate $signatureName Signature?',
  msg_are_you_sure_want_deactivate_signature:
    'Are you sure you want to deactivate $signatureName Signature?',
  msg_more_than_maxSortOrderValue_not_allowed:
    'Sorry, the value more than 999999999 is not allowed',
  msg_upload_file: 'Upload File',
  msg_edit_file: 'Edit File',
  msg_sorry_your_file_has_wrong_file_format:
    'Sorry your file has wrong format. Please upload the file in the format selected in File Type dropdown.',
  msg_no_file: 'Sorry there is no file. Please add a file.',
  msg_only_integers_allowed: 'Sorry, only integers (whole numbers) allowed',
  msg_unsaved_changes:
    'You have unsaved changes. You can discard your changes, or cancel to continue editing.',
  msg_save_faq_changes: 'Are you sure you want to save your changes?',
  msg_empty_faq: "Sorry, can't save an empty question or answer.",
  msg_users_totally_added: '$count users were successfully added to the system.',
  msg_not_cyrillic: 'Cyrillic characters are not supported',
  msg_deactivate_user: 'Are you sure you want to deactivate $userName user?',
  msg_activate_user: 'Are you sure you want to activate $userName user?',
  msg_field_cannot_be_empty: '$field cannot be empty',
  msg_are_you_sure_delete_QRC_item: 'Are you sure you want to delete $fileName?',
  msg_are_you_sure_delete_release_note_item: 'Are you sure you want to delete $name?',
  msg_create_release_note: 'Upload Release Note',
  msg__release_note_sorry_your_file_has_wrong_file_format:
    'Sorry your file has wrong format. Please upload PDF file.',
  msg_you_have_changed_user_type_dont_forget_to_assign:
    "You have changed User Type. Don't forget to assign new Permission Sets for this user.",
  msg_basic_counts: 'Basic Counts',
  msg_files_by_state: 'Files by State',
  msg_product_summary: 'Product Summary',
  msg_product_summary_by_state: 'Product Summary By State',
  msg_no_matches_found: 'Please adjust your search criteria and search again.',
  msg_permissions_changed:
    'Oops, your permissions have been changed. Please click the Refresh button to proceed.',
  msg_no_matches_by_keyword:
    "No matches found for keyword '$keyWord'. Please adjust your keyword search and try again.",
  msg_no_matches_by_searchFilters: "No matches found for '$keyWord'.",
  msg_permission_cant_be_removed:
    'A Permission Set can’t be removed because it is currently tied to Permission Items',
  msg_are_you_sure_you_want_to_delete_the_policy_type:
    'Are you sure you want to delete the policy type?',
  msg_policy_type_cant_be_removed: 'The policy type can’t be removed',
  msg_are_you_sure_you_want_to_delete_the_request: 'Are you sure you want to delete the request?',
  msg_request_cant_be_removed: 'The request can’t be removed',
  msg_activate_typeItem: 'Are you sure you want to activate $item Item?',
  msg_deactivate_typeItem: 'Are you sure you want to deactivate $item Item?',
  msg_are_you_sure_want_delete_attachment:
    'Are you sure want you to delete $attachmentName Attachment?',
  msg_are_you_sure_want_delete_this_property: 'Are you sure want you to delete $propertyName?',
  msg_are_you_sure_want_delete_this_approval: 'Are you sure want you to delete $approvalName?',
  msg_view_edit_user_company_access: 'View / Edit  Company Access',
  msg_are_you_sure_want_delete_company:
    'Are you sure want you to delete "$companyName" Company for $firstName $lastName user?',
  msg_are_you_sure_want_delete_property: 'Are you sure you want to delete $uwaPropertyID Property?',
  msg_are_you_sure_want_delete_companies:
    'Are you sure you want to delete $numberOfCompanies companies for $firstName $lastName user?',
  msg_are_sure_to_delete_all_companies:
    'Are you sure you want to remove all companies for $firstName $lastName user?',
  msg_please_select_new_requests: 'Please select at least one new request',
  msg_delete_several_companies:
    'Are you sure you want to remove companies for $firstName $lastName user?',
  msg_delete_users_from_company: 'Are you sure you want to remove users from $companyID company?',
  msg_remove_users_permissions: 'Are you sure you want to remove permission for the selected user?',
  msg_delete_all_users_from_company:
    'Are you sure you want to remove ALL users from $companyID company?',
  msg_start_date_must_be_before_end_date: 'Start date must be before End date',
  msg_end_date_must_be_after_start_date: 'End date must be after Start date',
  msg_are_you_sure_delete_branch: 'Are you sure want to delete $branchName Branch?',
  msg_are_you_sure_want_cancel_request_set_name:
    'Are you sure that you want to cancel $requestNumber request?',
  msg_view_edit_underwriter_configuration: 'Edit Underwriter Configuration',
  msg_only_underwriters_can_update_approval_level: 'Only Underwriters can update "Approval Level"',
  msg_you_dont_have_authority_to_change_approval_level:
    'You do not have proper authority to change the Approval Level for this request',
  msg_at_least_one_condition:
    "At least one Approval Condition activity record needs to be associated to this request before closing with a Status of 'Approved with Conditions'.",
  msg_at_least_one_attachment:
    'At least one attachment record needs to be associated to this request before closing.',
  msg_your_approval_level_is_less_than_required:
    "Your Approval Level is {UserApprovalLevel}. This Approval requires a level of {ApprovalLevel} or higher in order to set the status to '{ChosenStatus}'.",
  msg_are_sure_to_remove_all_companies:
    'Are you sure you want to remove ALL $number companies for $firstName $lastName user?',
  msg_are_sure_to_remove_all_users_from_company:
    'Are you sure you want to remove ALL $number users for $company Company?',
  msg_are_you_sure_delete_location: 'Are you sure want to delete the Location?',
  msg_are_you_sure_deactivate_company:
    'Are you sure you want to deactivate $company Company for this user?',
  msg_are_you_sure_activate_company:
    'Are you sure you want to activate $company Company for this user?',
  msg_are_you_sure_activate_branch:
    'Are you sure you want to activate $branch Branch for this Company?',
  msg_are_you_sure_deactivate_branch:
    'Are you sure you want to deactivate $branch Branch for this Company?',
  msg_request_unavailable: 'Request with number: $uwaRequestNumber unavailable',
  msg_are_you_sure_deactivate_location:
    'Are you sure you want to deactivate this Location For $branch Branch?',
  msg_are_you_sure_activate_location:
    'Are you sure you want to activate this Location For $branch Branch?',
  msg_user_updated_azure_b2c: 'User updated in Azure B2C successfully',
  msg_user_id_mismatch_solved: 'The user ID mismatch issue is solved successfully',
  msg_required_property_updated: 'The required property/field is updated successfully',
  msg_user_profile_updated: 'User Profile updated successfully',
  msg_select_policy_type: 'Select Policy Type',
  msg_proposed_policies: 'Proposed Policies',
  msg_select_owner: 'Select Owner',
  msg_are_you_sure_to_remove_this_uw_approval_item:
    'Are you sure you want to delete this UW Approval Item?',
  msg_must_be_x_characters: 'Field must have at least $number characters',
  msg_are_you_sure_delete_file_note: 'Are you sure you want to delete this File Note?',
  msg_are_you_sure_delete_request:
    'Are you sure that you want to delete this file? All file information entered will be removed.',
  msg_are_you_sure_leave:
    "Are you sure you want to leave? You haven't filled required fields: $requiredFields",
  msg_missing_fields: 'Required Field(s) are incomplete: $fields Please complete to assign owner.',
  msg_missing_county_fixed: 'The Missing County issue is fixed successfully.',
  msg_missing_legacy_id_fixed: 'The Missing Legacy ID issue is fixed successfully.',
  msg_incorrect_legacy_id_fixed: 'The Incorrect Legacy ID issue is fixed successfully.',
  msg_missing_transcode_fixed: 'The Missing Trans Code issue is fixed successfully.',

  msg_endozendounmatched_fixed: 'The ENDOZENDOUNMATCHED issue is fixed successfully.',
  msg_oldmigratedfilezxint_fixed: 'The OLDMIGRATEDFILEZXINT issue is fixed successfully.',
  msg_cplrevisevoidbadflag_fixed: 'The CPLREVISEVOIDBADFLAG issue is fixed successfully.',
  msg_configuration_not_available: 'Configuration currently not available.',
  msg_policy_amount_increase:
    'Policy Amount increase revisions may only be made by the Overlimit Request Admins. Please email the details to',
  msg_policy_amount_email: 'policyapprovalrequest@stewart.com',
  msg_filter_search_other_fields_validation:
    'Please enter a value in the Property State, Agency ID, Agency Name or Legacy ID column to continue.',
  msg_filter_search_file_id_validation:
    'Please enter a value in the File column and Property State, Agency ID, Agency Name or Legacy ID column to continue.',
  msg_uw_requested_approved_amount_not_equal: 'Does not match Requested Amount',
  msg_are_you_sure_want_delete_configuration: 'Do you want to delete this configuration?',
  msg_are_you_sure_want_save_configuration: 'Do you want to save this configuration?',
  msg_date_selected_is_out_of_range:
    'The date selected is outside the range specified in the advanced search.',
  msg_policy_images_transmitted_for_further_processing:
    'The below policy images have been transmitted for further processing.',
  msg_include_all_or_selected_images: 'Include only the selected image(s) or all images?',
  msg_policy_images_uploaded_today:
    'Below are policy images uploaded today. Images will be moved to the Archive tab at 11:59 PM CST.',
};

/**
 * Return Message by Tag or Tag List
 * @param {string | string[]} tag
 * @return {string}
 */
export function message(tag: string | string[]): string {
  if (isArray(tag)) {
    return tag.length
      ? tag.reduce((text: string, item: string): string => `${text} ${get(messages, item, '')}`, '')
      : NONEXISTENT_TEXT;
  }

  return get(messages, tag, NONEXISTENT_TEXT);
}
